import { useState, useEffect, useRef } from "react";
import {
  Chip,
  CircularProgress,
  Box,
  Paper,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Person as PersonIcon, Store as StoreIcon } from "@mui/icons-material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

import CustomToolbar from "../../table/CustomToolbar";
import axiosInstance from "../../utilities/axiosInstance";
import NewPagination from "../../components/newPagination";

const Customers = (props) => {
  const { setSelectedUsers, selectedUsers } = props;

  const [data, setData] = useState({
    users: [],
    page: 1,
    maxPage: 1,
  });

  const [text, setText] = useState("");

  const [selectedRows, setSelectedRows] = useState(selectedUsers ?? []);

  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });

  const [loading, setLoading] = useState(false);
  const apiRef = useRef(null);

  const columns = [
    {
      flex: 0.5,
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "display_name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "customer_type",
      headerName: "TYPE",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: true,
      flex: 1,
      renderCell: (params) =>
        params.value === "B2C" ? (
          <Chip icon={<PersonIcon />} label={params.value} />
        ) : (
          <Chip icon={<StoreIcon />} label={params.value} />
        ),
    },

    {
      field: "customer_role",
      headerName: "Customer Role",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },

    {
      field: "active",
      headerName: "ACTIVE",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  useEffect(() => {
    setSelectedUsers(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  // eslint-disable-next-line no-unused-vars
  const [customer_types, set_customer_types] = useState(["B2C", "B2B"]);
  // eslint-disable-next-line no-unused-vars
  const [customer_roles, set_customer_roles] = useState([
    "retailer",
    "architect",
    "interior",
    "oem_user",
    "end_user",
  ]);
  const [customer_type, setCustomer_type] = useState([]);
  const [customer_role, setCustomer_role] = useState([]);
  const [active, setActive] = useState("");

  const getAllUsers = async (page = 1) => {
    setLoading(true);

    if (apiRef.current) {
      apiRef.current.abort();
    }

    apiRef.current = new AbortController();

    const filters = [];

    if (customer_role.length) {
      filters.push({ field: "customer_role", value: customer_role });
    }

    if (customer_type.length) {
      filters.push({ field: "customer_type", value: customer_type });
    }

    if (active) {
      filters.push({ field: "active", value: active });
    }

    axiosInstance
      .get(
        `/customers?page=${page}&sort=${JSON.stringify(
          sort
        )}&text=${text}&filters=${JSON.stringify(filters)}`,
        {
          signal: apiRef.current.signal,
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, text, customer_role, customer_type, active]);

  return (
    <div
      className='relative'
      style={{
        minWidth: "800px",
      }}
    >
      {loading ? (
        <div className='absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-white opacity-50'>
          <CircularProgress />
        </div>
      ) : null}

      <div className='my-2 px-2 grid grid-flow-row grid-cols-3 gap-4'>
        <TextField
          size='small'
          fullWidth
          id='text-search'
          label='Search'
          placeholder='Search for name, email, phone'
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          style={{
            gridColumn: "1 / -1",
          }}
        />

        <Autocomplete
          id='customer_type'
          multiple
          size='small'
          value={customer_type}
          onChange={(e, v) => setCustomer_type(v)}
          options={customer_types.map((e) => {
            return e;
          })}
          renderInput={(params) => (
            <TextField {...params} label='Customer Type' />
          )}
        />

        <Autocomplete
          id='customer_role'
          multiple
          size='small'
          value={customer_role}
          onChange={(e, v) => setCustomer_role(v)}
          options={customer_roles.map((e) => {
            return e;
          })}
          renderInput={(params) => (
            <TextField {...params} label='Customer Roles' />
          )}
        />

        <Autocomplete
          id='active'
          size='small'
          value={active}
          onChange={(e, v) => setActive(v)}
          options={["true", "false"].map((e) => {
            return e;
          })}
          renderInput={(params) => <TextField {...params} label='Status' />}
        />
      </div>

      <Box
        className='shadow'
        component={Paper}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#243750",
            color: "#ffffff",
            cursor: "default",
          },
        }}
      >
        <DataGrid
          rows={data?.users?.map((user) => ({
            display_name: user?.display_name,
            email: user?.email,
            phone: user?.phone,
            customer_type: user?.customer_type,
            customer_role: user?.customer_role,
            active: user?.active,
            id: user?.id,
          }))}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          filterMode='server'
          sortingMode='server'
          paginationMode='server'
          disableRowSelectionOnClick
          checkboxSelection={true}
          autoHeight
          autoPageSize
          onFilterModelChange={(filter) => {
            // setFilter(filter.items[0]);
            // setSearch(filter.quickFilterValues[0]);
          }}
          keepNonExistentRowsSelected
          onRowSelectionModelChange={(newRowSelectionModel) => {
            console.log(newRowSelectionModel);
            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".PrivateSwitchBase-input": {
              height: "23px",
              margin: "10px 13px",
              width: "20px",
            },
            cursor: "pointer",
          }}
          onRowClick={(params) => {}}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          onSortModelChange={(sort) => {
            setSort(sort[0]);
          }}
        />
      </Box>

      {data?.maxPage > 1 && (
        <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
          <NewPagination totalPage={data} fetchData={getAllUsers} />
        </div>
      )}
    </div>
  );
};

export default Customers;
