import { Fragment, useState, useEffect } from "react";
import {
  SmsFailed,
  MotionPhotosAuto,
  AssignmentLate,
  ImportExport,
  MarkChatRead,
  Campaign,
} from "@mui/icons-material";
import { Button, Autocomplete, TextField, Tooltip } from "@mui/material";
import moment from "moment";

import Classes from "./css/dashboard.module.css";
import Spinner from "../../../components/spinner";

import axiosInstance from "../../../utilities/axiosInstance";
import { downloadCSV } from "../../../utilities/downloadCSV";
import BarChartComponent from "../../../components/barChartComponent";

import MessagesTable from "../../../containers/messagesTable";
import UnAssignedChats from "../../../containers/unAssignedChats";

const Card = (props) => {
  const {
    heading,
    percentage,
    title,
    color,
    ofLastDay,
    icon,
    selectedTable,
    setSelectedTable,
    statuses,
    tabName,
    setActiveTemplate,
    template,
    activeTemplate,
  } = props;
  return (
    <div
      className={`${Classes.Card} cursor-pointer`}
      style={{
        backgroundColor:
          selectedTable === title ||
          (selectedTable === tabName && template === activeTemplate)
            ? "#70809055"
            : color,
      }}
      onClick={() => {
        if (
          (selectedTable === tabName && activeTemplate === template) ||
          selectedTable === title
        ) {
          setSelectedTable(null);
        } else {
          setSelectedTable(tabName ?? title);
          setActiveTemplate?.(template);
        }
      }}
    >
      <div className='relative'>
        <Tooltip title={title}>
          <p className={Classes.CardTitle}>
            {title.length > 20 ? title.substring(0, 20) + "..." : title}
          </p>
        </Tooltip>
        <span
          style={{
            fontSize: "12px",
            position: "absolute",
            right: -5,
            top: -8,
            backgroundColor: "#a0cefa",
            borderRadius: "6px",
            padding: "2px 4px",
          }}
        >
          {ofLastDay ? `Last ${ofLastDay} days` : "Today"}
        </span>
      </div>

      <div className='flex items-center justify-between'>
        <div
          className={Classes.CardHeading}
          style={{ display: "flex", alignContent: "center" }}
        >
          <div style={{ marginRight: "5px" }}>{icon}</div>
          <div style={{ marginTop: ".2rem" }}>{heading}</div>
        </div>

        {percentage ? <p>{percentage} %</p> : null}
      </div>

      {statuses ? (
        <div
          style={{
            color: "rgba(97, 94, 97, 1)",
          }}
        >
          {statuses?.map((e, index) => {
            return (
              <div
                key={e.status + index + title}
                className='grid grid-flow-row'
                style={{
                  gridTemplateColumns: "1fr 1fr 2fr",
                }}
              >
                <p className='uppercase'>{e.status}</p>
                <p>{e.total}</p>
                <p className='text-end'>{e.percentage} %</p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const Messages = (props) => {
  const { startDate, endDate, changeDates } = props;

  const [numberOfDays, setNumberOfDays] = useState(0);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [temps, setTemps] = useState([]);
  const [templateDataS, setTemplateDataS] = useState([]);

  const [failedMessagesCount, setFailedMessagesCount] = useState(null);
  const [successMessagesCount, setSuccessMessagesCount] = useState(null);
  const [automatedMessagesCount, setAutomatedMessagesCount] = useState(null);
  const [unAssignedChatsCount, setUnAssignedChatsCount] = useState(0);

  const [selectedTable, setSelectedTable] = useState(null);

  const getTemplates = async () => {
    axiosInstance
      .get("/chats/templates")
      .then((res) => {
        setTemplates(res.data?.data ?? []);
      })
      .catch((err) => {});
  };

  const fetchNumberOfDays = (startDate, endDate) => {
    const numOfDays = moment(endDate).diff(startDate, "days");
    setNumberOfDays(numOfDays);
  };

  const getFailedMessagesCount = async () => {
    await axiosInstance
      .get(
        `/dashboard/failedMessagesCount?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setFailedMessagesCount(res.data.data);
      })
      .catch((err) => {});
  };

  const getAutomatedMessagesCount = async () => {
    await axiosInstance
      .get(
        `/dashboard/getAutomatedMessagesCount?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setAutomatedMessagesCount(res.data.data);
      })
      .catch((err) => {});
  };

  const getUnAssignedChatsCount = async () => {
    await axiosInstance
      .get(
        `/dashboard/getUnAssignedChatsCount?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setUnAssignedChatsCount(res.data.data);
      })
      .catch((err) => {});
  };

  const getSuccessMessagesCount = async () => {
    await axiosInstance
      .get(
        `/dashboard/successMessagesCount?startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        setSuccessMessagesCount(res.data.data);
      })
      .catch((err) => {});
  };

  const getTemplateMessagesCount = async (templateName) => {
    return axiosInstance
      .get(
        `/dashboard/templateMessagesCount?startDate=${startDate}&endDate=${endDate}&templateName=${templateName}`
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        throw new Error(
          err?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const services = async () => {
    if (startDate && endDate) {
      fetchNumberOfDays(startDate, endDate);
      setLoading(true);
      await getTemplates();
      await getFailedMessagesCount();
      await getSuccessMessagesCount();
      await getAutomatedMessagesCount();
      await getUnAssignedChatsCount();
      setLoading(false);
    }
  };

  useEffect(() => {
    services();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    setLoading(true);

    Promise.all(temps.map(getTemplateMessagesCount))
      .then((results) => {
        setTemplateDataS(results);
      })
      .catch((err) => {
        setTemplateDataS([]);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, temps]);

  const onClickBar = (index, title) => {
    if (title === "Failed Messages") {
      const dateString =
        failedMessagesCount?.messagesByDate?.[index].createdAt ?? null;

      if (dateString) {
        changeDates(dateString);
        setSelectedTable(title);
      }
    } else if (title === "Automated Messages") {
      const dateString =
        automatedMessagesCount?.messagesByDate?.[index].createdAt ?? null;
      if (dateString) {
        changeDates(dateString);
        setSelectedTable(title);
      }
    } else if (title === "Success Messages") {
      const dateString =
        automatedMessagesCount?.messagesByDate?.[index].createdAt ?? null;
      if (dateString) {
        changeDates(dateString);
        setSelectedTable(title);
      }
    } else {
      const dateString =
        automatedMessagesCount?.messagesByDate?.[index].createdAt ?? null;
      if (dateString) {
        changeDates(dateString);
        setSelectedTable("Template Messages");
        setActiveTemplate(title.split(" ")[0].toLowerCase());
      }
    }
  };

  return (
    <Fragment>
      <div className='flex items-center justify-end mb-4 gap-4'>
        <Autocomplete
          id='template'
          size='small'
          fullWidth
          multiple
          value={temps}
          onChange={(e, v) => {
            setTemps(v);
          }}
          options={templates.map((e) => {
            return e.name;
          })}
          renderInput={(params) => (
            <TextField {...params} label='Select Template' />
          )}
          style={{
            maxWidth: "500px",
          }}
        />

        <ExportButton
          arrayOfObject={templateDataS.map((e) => {
            return {
              "Start Date": startDate,
              "End Date": endDate,
              "Total Messages": e.totalMessagesCount,
              Template: e.template.toUpperCase(),
              [`Total Template Messages`]: `${e.totalCount}  (${e.percentage}%)`,
              ...e.statuses.reduce(
                (obj, item) =>
                  Object.assign(obj, {
                    [`${item.status.toUpperCase()}`]: `${item.total}  (${item.percentage}%)`,
                  }),
                {}
              ),
            };
          })}
          fileName={"template-messages-stats"}
        />
      </div>
      <div className={Classes.Container}>
        <div className={Classes.CardContainer}>
          <Card
            heading={failedMessagesCount?.totalCount}
            percentage={failedMessagesCount?.percentage}
            title='Failed Messages'
            color='#dddddd56'
            icon={<SmsFailed />}
            ofLastDay={numberOfDays}
            setSelectedTable={setSelectedTable}
            selectedTable={selectedTable}
          />

          <Card
            heading={successMessagesCount?.totalCount}
            percentage={successMessagesCount?.percentage}
            title='Success Messages'
            color='#dddddd56'
            icon={<MarkChatRead />}
            ofLastDay={numberOfDays}
            setSelectedTable={setSelectedTable}
            selectedTable={selectedTable}
          />

          <Card
            heading={automatedMessagesCount?.totalCount}
            percentage={automatedMessagesCount?.percentage}
            title='Automated Messages'
            color='#dddddd56'
            icon={<MotionPhotosAuto />}
            ofLastDay={numberOfDays}
            setSelectedTable={setSelectedTable}
            selectedTable={selectedTable}
          />

          <Card
            heading={unAssignedChatsCount}
            title='UnAssigned Chats'
            color='#dddddd56'
            icon={<AssignmentLate />}
            ofLastDay={numberOfDays}
            setSelectedTable={setSelectedTable}
            selectedTable={selectedTable}
          />

          {templateDataS
            ? templateDataS?.map((templateData, index) => {
                return (
                  <div
                    key={"Card" + templateData.template + index}
                    style={{
                      gridRow: "span 2",
                    }}
                  >
                    <Card
                      heading={templateData?.totalCount}
                      percentage={templateData?.percentage}
                      title={`${templateData.template.toUpperCase()} Messages`}
                      color='#dddddd56'
                      icon={<Campaign />}
                      ofLastDay={numberOfDays}
                      setSelectedTable={setSelectedTable}
                      selectedTable={selectedTable}
                      statuses={templateData?.statuses}
                      tabName={"Template Messages"}
                      activeTemplate={activeTemplate}
                      template={templateData.template}
                      setActiveTemplate={setActiveTemplate}
                    />
                  </div>
                );
              })
            : null}
        </div>

        {!loading && selectedTable ? (
          <div className='mt-4'>
            <h3 className='text-lg font-medium mb-1'>
              {selectedTable === "Template Messages"
                ? `${activeTemplate?.toLocaleUpperCase()} Messages`
                : selectedTable}
            </h3>
            {selectedTable === "Failed Messages" ? (
              <MessagesTable
                endpoint={"failedMessages"}
                startDate={startDate}
                endDate={endDate}
              />
            ) : null}

            {selectedTable === "Success Messages" ? (
              <MessagesTable
                endpoint={"successMessages"}
                startDate={startDate}
                endDate={endDate}
              />
            ) : null}

            {selectedTable === "Automated Messages" ? (
              <MessagesTable
                endpoint={"automatedMessages"}
                startDate={startDate}
                endDate={endDate}
              />
            ) : null}

            {selectedTable === "Template Messages" ? (
              <MessagesTable
                endpoint={"templateMessages"}
                startDate={startDate}
                endDate={endDate}
                template={activeTemplate}
              />
            ) : null}

            {selectedTable === "UnAssigned Chats" ? (
              <UnAssignedChats startDate={startDate} endDate={endDate} />
            ) : null}
          </div>
        ) : null}

        {!loading && !selectedTable ? (
          <div className={Classes.GraphContainer}>
            {failedMessagesCount?.messagesByDate.length ? (
              <div className={Classes.Graph}>
                <ExportButton
                  arrayOfObject={failedMessagesCount?.messagesByDate}
                  fileName={`Total Failed Messages From ${startDate} to ${endDate}`}
                />
                <div className={Classes.GraphHeight}>
                  <BarChartComponent
                    onClickBar={onClickBar}
                    title2='Failed Messages'
                    label='Total Failed Messages Per day'
                    labels={failedMessagesCount?.messagesByDate.map(
                      (item) => item?.createdAt
                    )}
                    data={failedMessagesCount?.messagesByDate.map(
                      (item) => +item?.count
                    )}
                    title={`Total Failed Messages of Last ${numberOfDays} days : ${failedMessagesCount?.totalCount}`}
                  />
                </div>
              </div>
            ) : null}

            {successMessagesCount?.messagesByDate.length ? (
              <div className={Classes.Graph}>
                <ExportButton
                  arrayOfObject={successMessagesCount?.messagesByDate}
                  fileName={`Total Success Messages From ${startDate} to ${endDate}`}
                />
                <div className={Classes.GraphHeight}>
                  <BarChartComponent
                    onClickBar={onClickBar}
                    title2='Success Messages'
                    label='Total Success Messages Per day'
                    labels={successMessagesCount?.messagesByDate.map(
                      (item) => item?.createdAt
                    )}
                    data={successMessagesCount?.messagesByDate.map(
                      (item) => +item?.count
                    )}
                    title={`Total Success Messages of Last ${numberOfDays} days : ${successMessagesCount?.totalCount}`}
                  />
                </div>
              </div>
            ) : null}

            {automatedMessagesCount?.messagesByDate.length ? (
              <div className={Classes.Graph}>
                <ExportButton
                  arrayOfObject={automatedMessagesCount?.messagesByDate}
                  fileName={`Total Automated Messages From ${startDate} to ${endDate}`}
                />
                <div className={Classes.GraphHeight}>
                  <BarChartComponent
                    onClickBar={onClickBar}
                    title2='Automated Messages'
                    label='Total Automated Messages Per day'
                    labels={automatedMessagesCount?.messagesByDate.map(
                      (item) => item?.createdAt
                    )}
                    data={automatedMessagesCount?.messagesByDate.map(
                      (item) => +item?.count
                    )}
                    title={`Total Automated Messages of Last ${numberOfDays} days : ${automatedMessagesCount?.totalCount}`}
                  />
                </div>
              </div>
            ) : null}

            {templateDataS
              ? templateDataS.map((templateData, index) => {
                  return (
                    <div
                      className={Classes.Graph}
                      key={"Graph" + templateData.template + index}
                    >
                      <ExportButton
                        arrayOfObject={templateData?.messagesByDate}
                        fileName={`Total ${templateData.template.toUpperCase()} Messages From ${startDate} to ${endDate}`}
                      />
                      <div className={Classes.GraphHeight}>
                        <BarChartComponent
                          onClickBar={onClickBar}
                          title2={`${templateData.template.toUpperCase()} Messages`}
                          label={`Total ${templateData.template.toUpperCase()} Messages Per day`}
                          labels={templateData?.messagesByDate.map(
                            (item) => item?.createdAt
                          )}
                          data={templateData?.messagesByDate.map(
                            (item) => +item?.count
                          )}
                          title={`Total ${templateData.template.toUpperCase()} Messages of Last ${numberOfDays} days : ${
                            templateData?.totalCount
                          }`}
                        />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
      </div>

      {loading && <Spinner />}
    </Fragment>
  );
};

export default Messages;

const ExportButton = (props) => {
  const { arrayOfObject, fileName = "CSV EXPORT" } = props;
  const handleExport = (data, fileName) => {
    if (data && Array.isArray(data)) {
      downloadCSV(data, fileName);
    }
  };
  return (
    <>
      <Button
        size='small'
        variant='outlined'
        startIcon={<ImportExport />}
        sx={{
          color: "rgba(255, 98, 0,1)",
          borderColor: "rgba(255, 98, 0,1)",
          ":hover": {
            backgroundColor: "rgba(255, 98, 0,1)",
            color: "white",
            borderColor: "rgba(255, 98, 0,1)",
          },
        }}
        onClick={() => handleExport(arrayOfObject, fileName)}
      >
        Export
      </Button>
    </>
  );
};
