import { useState } from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import Classes from "./css/dashboard.module.css";

import Messages from "./messages";

const Dashboard = () => {
  const [alignment, setAlignment] = useState("messages");

  const getFormattedDate = (date) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  const [startDate, setStartDate] = useState(() =>
    getFormattedDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000))
  ); // set last 15 days date by default
  const [endDate, setEndDate] = useState(() => getFormattedDate(new Date()));

  const handleChange = (event) => {
    setAlignment(event.target.value);
  };

  const changeDates = (dateString) => {
    setStartDate(dateString);
    setEndDate(dateString);
  };

  function DateRange() {
    return (
      <div className={Classes.DatePickerContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Start Date'
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "9.5px 8px",
                width: "105px",
                fontSize: "14px",
              },
              "& .MuiSvgIcon-root": { fontSize: "18px" },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#0000003b !important",
              },
              "& .Mui-error": { color: "#00000099 !important" },
            }}
            inputFormat='YYYY-MM-DD'
            size='small'
            format='YYYY-MM-DD'
            defaultValue={dayjs(startDate)}
            value={dayjs(startDate)}
            onChange={(newValue) => {
              setStartDate(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
            }}
          />
          <DatePicker
            label='End Date'
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "9.5px 8px",
                width: "105px",
                fontSize: "14px",
              },
              "& .MuiSvgIcon-root": { fontSize: "18px" },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#0000003b !important",
              },
              "& .Mui-error": { color: "#00000099 !important" },
            }}
            inputFormat='YYYY-MM-DD'
            size='small'
            format='YYYY-MM-DD'
            defaultValue={dayjs(endDate)}
            value={dayjs(endDate)}
            onChange={(newValue) => {
              setEndDate(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
            }}
          />
        </LocalizationProvider>
      </div>
    );
  }

  return (
    <div className='relative'>
      <p>Dashboard</p>

      <div className={Classes.DashboardContainer}>
        <div className={Classes.DashboardHeader}>
          <div
            style={{
              backgroundColor: "#9e9e9e1f",
              borderRadius: 4,
              padding: 4,
            }}
          >
            <ToggleButtonGroup
              color='primary'
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label='Platform'
              sx={{
                "& .MuiToggleButtonGroup-grouped": {
                  border: "0px",
                  color: "black",
                  textTransform: "capitalize",
                  padding: "2px 11px",
                },
                "& .MuiToggleButton-root.Mui-selected": {
                  backgroundColor: "#fff",
                  color: "#53a6fd",
                },
              }}
            >
              <ToggleButton value='messages'>Messages</ToggleButton>
            </ToggleButtonGroup>
          </div>

          <DateRange />
        </div>
      </div>

      {alignment === "messages" && (
        <Messages
          startDate={startDate}
          endDate={endDate}
          changeDates={changeDates}
        />
      )}
    </div>
  );
};

export default Dashboard;
