import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Alert,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";

import axiosInstance from "../../../utilities/axiosInstance";
import { set as setSnackbar } from "../../../store/snackbarSlice";

const RequestedTemplate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const screenWidth = useSelector((state) => state.screen.screenWidth);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [header_type, set_header_type] = useState("");
  const [header_media, set_header_media] = useState(null);

  const header_media_ref = useRef(null);

  const [loading0, setLoading0] = useState(false);
  const getData = async () => {
    setLoading0(true);
    setError(null);

    axiosInstance
      .get(`/chats/requestTemplates/${id}`)
      .then((res) => {
        set_header_type(res.data.data.header_type ?? "");
        setData(res.data.data);
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading0(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);
  const submit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    formData.delete("header_media");

    if (!header_type) {
      formData.delete("header_type");
    }

    if (header_type && header_type !== "TEXT" && header_media) {
      formData.append("media", header_media);
    }

    setLoading(true);

    axiosInstance
      .post(`/chats/requestTemplates/${id}`, formData)
      .then((res) => {
        set_header_type(res.data.data.header_type ?? "");
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getMedia = () => {
    axiosInstance
      .get(`/chats/requestTemplates/media/${id}`)
      .then((res) => {
        window.open(res.data.data, "_blank");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err.response?.data?.message,
            open: true,
          })
        );
      });
  };

  const [loading1, setLoading1] = useState(false);
  const updateStatus = async () => {
    setLoading1(true);

    axiosInstance
      .post(`/chats/requestTemplates/updateStatus/${id}`)
      .then((res) => {
        set_header_type(res.data.data.header_type ?? "");
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  const [loading2, setLoading2] = useState(false);
  const reject = async () => {
    setLoading2(true);

    axiosInstance
      .post(`/chats/requestTemplates/reject/${id}`)
      .then((res) => {
        set_header_type(res.data.data.header_type ?? "");
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  return (
    <div>
      {loading0 ? (
        <div className='flex justify-center items-center h-full'>
          <CircularProgress />
        </div>
      ) : null}
      {error ? <Alert severity='error'>{error}</Alert> : null}

      {!loading0 && !error && data ? (
        <form
          onSubmit={submit}
          className={`mt-4 grid grid-flow-row ${
            screenWidth <= 700 ? "grid-cols-1" : "grid-cols-2"
          } gap-8`}
        >
          <TextField
            size='small'
            fullWidth
            id='name'
            label='Name'
            name='name'
            required
            defaultValue={data.name}
            disabled={loading}
          />

          <FormControl fullWidth>
            <InputLabel id='label-language'>Language *</InputLabel>
            <Select
              labelId='label-language'
              size='small'
              id='language'
              name='language'
              required
              disabled={loading}
              defaultValue={data.language}
              input={<OutlinedInput notched label={"Language"} />}
            >
              <MenuItem value='english'>English</MenuItem>
              <MenuItem value='hindi'>Hindi</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id='label-category'>Category *</InputLabel>
            <Select
              labelId='label-category'
              size='small'
              id='category'
              name='category'
              required
              disabled={loading}
              defaultValue={data.category}
              input={<OutlinedInput notched label={"Category"} />}
            >
              <MenuItem value='UTILITY'>Utility</MenuItem>
              <MenuItem value='MARKETING'>Marketing</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id='label-header-type'>Header Type</InputLabel>
            <Select
              labelId='label-header-type'
              size='small'
              id='header_type'
              name='header_type'
              disabled={loading}
              input={<OutlinedInput notched label={"Header Type"} />}
              value={header_type}
              onChange={(e) => {
                set_header_type(e.target.value);
              }}
            >
              <MenuItem value=''>NONE</MenuItem>
              <MenuItem value='TEXT'>Text</MenuItem>
              <MenuItem value='IMAGE'>Image</MenuItem>
              <MenuItem value='DOCUMENT'>Document</MenuItem>
            </Select>
          </FormControl>

          {header_type === "TEXT" ? (
            <TextField
              size='small'
              fullWidth
              id='header_text'
              label='Header Text'
              name='header_text'
              required
              disabled={loading}
              defaultValue={data.header_text || ""}
              helperText='To use placeholders use {{PLACEHOLDER_SR_NO}} format. ex. {{1}}'
            />
          ) : header_type ? (
            <div
              className='border rounded p-2 pt-3 border-gray-400 relative'
              onClick={() => {
                header_media_ref?.current?.click();
              }}
            >
              <p className='absolute left-4 top-0 -translate-y-1/2 bg-white text-gray-500 px-1 text-xs font-light'>
                Header Media
              </p>
              <input
                onClick={(e) => {
                  e.stopPropagation();
                }}
                ref={header_media_ref}
                required={!data.header_media}
                disabled={loading}
                type='file'
                accept={
                  header_type === "DOCUMENT"
                    ? ".pdf, .csv, .xlxs"
                    : header_type === "IMAGE"
                    ? ".png, .jpeg, .jpg, .webp"
                    : ""
                }
                name='header_media'
                onChange={(e) => {
                  set_header_media(e.currentTarget.files[0]);
                }}
              />

              {data.header_media ? (
                <p
                  className='cursor-pointer text-blue-700 mt-2'
                  onClick={(e) => {
                    e.stopPropagation();
                    getMedia();
                  }}
                >
                  Open Media
                </p>
              ) : null}
            </div>
          ) : null}

          {header_type ? <div></div> : null}

          <TextField
            multiline
            minRows={2}
            size='small'
            fullWidth
            id='body_text'
            label='Body Text'
            name='body_text'
            required
            disabled={loading}
            defaultValue={data.body_text ?? ""}
            helperText='To use placeholders use {{PLACEHOLDER_SR_NO}} format. ex. {{1}}'
          />

          <TextField
            size='small'
            fullWidth
            id='footer_text'
            label='Footer Text'
            name='footer_text'
            helperText="Can't use placeholder for footer text."
            disabled={loading}
            defaultValue={data.footer_text ?? ""}
          />

          <div
            className='flex justify-center'
            style={{
              gridColumn: "1 / -1",
            }}
          >
            <Button
              disabled={
                loading ||
                data.status === "APPROVED" ||
                data.status === "CREATED" ||
                data.status === "REJECTED"
              }
              type='submit'
              variant='contained'
              size='small'
              style={{
                width: "120px",
              }}
            >
              {loading ? (
                <CircularProgress size={24} className='!text-white' />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </form>
      ) : null}

      {data ? (
        <div className='grid grid-flow-row grid-cols-2 w-fit gap-8 mx-auto mt-8'>
          <Button
            className='!bg-green-600'
            disabled={data.status === "REJECTED"}
            variant='contained'
            size='small'
            style={{
              width: "120px",
            }}
            onClick={() => {
              updateStatus();
            }}
          >
            {loading1 ? (
              <CircularProgress size={24} className='!text-white' />
            ) : (
              data.status
            )}
          </Button>

          <Button
            className='!bg-red-600'
            disabled={data.status === "CREATED"}
            variant='contained'
            size='small'
            style={{
              width: "120px",
            }}
            onClick={() => {
              reject();
            }}
          >
            {loading2 ? (
              <CircularProgress size={24} className='!text-white' />
            ) : data.status === "REJECTED" ? (
              "REJECTED"
            ) : (
              "Reject"
            )}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default RequestedTemplate;
