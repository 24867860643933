import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, InputAdornment } from "@mui/material";
import { KeyboardBackspace, Search, Loop, Clear } from "@mui/icons-material";

import axiosInstance from "../utilities/axiosInstance";
import ChatListItem from "../components/chatListItem2";

import { set as setSnackbar } from "../store/snackbarSlice";
import { setChatsSearchData } from "../store/chatsSlice";

const ChatSearch = (props) => {
  const dispatch = useDispatch();

  const { activeChat, selectChat, filter, unreadMessages } = props;

  const inputRef = useRef();
  const [text, setText] = useState("");
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancelRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isNotAChat, setIsNotAChat] = useState(false);

  const data = useSelector((state) => state.chats.chatsSearchData);
  const notifications = useSelector((state) => state.notifications);

  const search = async (value) => {
    cancelRef.current?.abort();

    if (!value) {
      dispatch(
        setChatsSearchData({
          users: [],
          chats: [],
        })
      );

      setLoading(false);
      return;
    }
    setLoading(true);

    cancelRef.current = new AbortController();

    axiosInstance
      .get(
        `/chats/search?text=${value}&filter=${filter}&unreadMessages=${unreadMessages}`,
        {
          signal: cancelRef.current?.signal,
        }
      )
      .then((res) => {
        dispatch(setChatsSearchData(res.data.data));
        setShow(true);

        if (value.length === 10 && !isNaN(value)) {
          getChatByPhoneNumber(value, cancelRef.current?.signal);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (show) {
      search(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, notifications.updateChatsData]);

  const convertToChat = async () => {
    axiosInstance
      .post("/chats/convertToChat", { phone: `+91${text}` })
      .then((res) => {
        selectChat(res.data.data);
        setShow(false);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      });
  };

  const getChatByPhoneNumber = async (phone, signal) => {
    axiosInstance
      .get(`/chats/chatByPhoneNumber/${phone}`, {
        signal,
      })
      .then((res) => {
        setIsNotAChat(false);
      })
      .catch((err) => {
        setIsNotAChat(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className='p-2 overflow-visible'>
      <div
        className='absolute left-0 w-full bottom-0 transition-all z-50 overflow-auto'
        style={{
          background: "#111b21",
          top: (focused || show) && text ? "130px" : "100%",
          padding: (focused || show) && text ? "8px" : "0px",
        }}
      >
        {(!data?.chats || !data?.chats?.length) &&
        (!data?.users || !data?.users?.length) &&
        !loading &&
        text &&
        text.length === 10 &&
        !isNaN(text) &&
        isNotAChat ? (
          <div
            className='mb-4 flex flex-col justify-center items-center p-4 cursor-pointer'
            style={{
              color: "#d1d7db",
              backgroundColor: "#2a3942",
            }}
            onClick={() => {
              convertToChat();
            }}
          >
            <p className='text-sm'>Start Conversation with</p>
            <p
              className='text-white'
              style={{
                color: "#00a884",
              }}
            >
              {text}
            </p>
          </div>
        ) : null}

        <p style={{ color: "#00a884" }}>Chats</p>
        {data?.chats.map((ch) => (
          <ChatListItem
            key={ch.phone}
            chatListItem={ch}
            activeChat={activeChat}
            selectChat={selectChat}
            isChat={true}
            setShow={setShow}
          />
        ))}
        <p className='mt-4' style={{ color: "#00a884" }}>
          Users
        </p>
        {data?.users.map((ch) => (
          <ChatListItem
            key={ch.phone}
            chatListItem={ch}
            activeChat={activeChat}
            selectChat={selectChat}
            isChat={false}
            setShow={setShow}
          />
        ))}
      </div>
      <TextField
        id='search-chats-and-contacts'
        fullWidth
        value={text}
        placeholder='Search or start new chat'
        onChange={(e) => {
          setText(e.target.value);
          search(e.target.value);
        }}
        inputRef={inputRef}
        onFocus={() => {
          setFocused(true);
          if (text) {
            setShow(true);
          }
        }}
        onBlur={() => {
          setFocused(false);
        }}
        style={{
          borderRadius: "8px",
        }}
        inputProps={{
          style: {
            padding: "0.5rem 1rem",
          },
        }}
        InputProps={{
          style: {
            fontSize: "14px",
            fontWeight: "400",
            backgroundColor: "#202c33",
            color: "#d1d7db",
          },
          startAdornment: (
            <InputAdornment
              position='start'
              className={"cursor-pointer"}
              style={{ color: focused ? "#00a884" : "#8696a0" }}
              // onClick={() => {
              //   if (!focused) {
              //     inputRef.current?.focus();
              //   } else {
              //     setText("");
              //     setShow(false);
              //   }
              // }}
            >
              {focused || text ? (
                <KeyboardBackspace
                  onClick={() => {
                    setText("");
                    setShow(false);
                  }}
                />
              ) : (
                <Search
                  onClick={() => {
                    inputRef.current?.focus();
                  }}
                />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position='end'
              className={loading ? "cursor-default" : "cursor-pointer"}
              style={{ color: "#8696a0" }}
              // onClick={() => {
              //   if (loading) {
              //     return;
              //   }

              //   setText("");
              //   inputRef.current?.focus();
              // }}
            >
              {loading ? (
                <Loop className='rotating' />
              ) : text ? (
                <Clear
                  onClick={() => {
                    setText("");
                    setShow(false);
                  }}
                />
              ) : null}
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "none",
              "&:hover fieldset": {},
              "&.Mui-focused fieldset": {},
            },
          },
        }}
      />
    </div>
  );
};

export default ChatSearch;
