import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  user: null,
  loading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    set: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    reset: (state, action) => ({
      ...initialState,
    }),
  },
});

export const { set } = authSlice.actions;

export default authSlice.reducer;
