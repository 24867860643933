import { Fragment } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CheckCircle } from "@mui/icons-material";

import axiosInstance from "../../../utilities/axiosInstance";
import { set as setSnackbar } from "../../../store/snackbarSlice";
import CustomNoRowsOverlay from "../../../components/customNoOverlay";

const Groups = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading2, setLoading2] = useState(false);
  const [allowEveryOneToRespond, setAlloWEveryoneToRespond] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    groups: [],
    pagination: {
      page: 1,
      maxPage: 1,
      total: 0,
    },
  });

  const getData = async () => {
    setLoading(true);

    axiosInstance
      .get("/groups")
      .then((res) => {
        setData({
          ...data,
          ...res.data.data,
        });
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: err.response?.data?.message,
            severity: "error",
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getData2 = async () => {
    setLoading2(true);

    axiosInstance
      .get("/chats2/allowEveryoneToRespond")
      .then((res) => {
        setAlloWEveryoneToRespond(res.data.data.allow);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: err.response?.data?.message,
            severity: "error",
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    getData2();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAllowEveryoneToRespond = async () => {
    setLoading2(true);

    axiosInstance
      .post("/chats2/allowEveryoneToRespond", {
        allow: !allowEveryOneToRespond,
      })
      .then((res) => {
        setAlloWEveryoneToRespond(res.data.data.allow);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: err.response?.data?.message,
            severity: "error",
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", hide: true, width: 100 },
    { field: "name", headerName: "NAME", width: 150 },
    {
      field: "default",
      headerName: "IS Default",
      width: 150,
      renderCell: (params) => {
        if (params.value === true) {
          return <CheckCircle className='!text-green-600' />;
        } else {
          return null;
        }
      },
    },
    // { field: "email", headerName: "EMAIL", width: 200 },
    // { field: "display_name", headerName: "DISPLAY NAME", width: 200 },
    // { field: "role", headerName: "ROLE", width: 150 },
  ];

  return (
    <Fragment>
      <Box
        component='div'
        className='flex items-center justify-between flex-wrap rounded bg-gray-100 p-2 mb-4'
      >
        <p className='font-medium text-lg'>Groups</p>

        <div className='flex items-center flex-wrap gap-4'>
          <Button
            variant='contained'
            className={allowEveryOneToRespond ? "!bg-green-600" : "!bg-red-600"}
            onClick={() => {
              updateAllowEveryoneToRespond();
            }}
            disabled={loading2}
          >
            Allow Everyone To Respond
          </Button>

          <Button
            variant='contained'
            onClick={() => {
              navigate(`create-new-group`);
            }}
          >
            Create New Group
          </Button>
        </div>
      </Box>

      <Box
        component='div'
        style={{
          height: data.groups.length === 0 ? "500px" : "auto",
          maxWidth: "calc(100vw - 1rem)",
        }}
        className='overflow-auto'
      >
        <DataGrid
          rowSelection={false}
          loading={loading}
          rows={data.groups}
          columns={columns}
          checkboxSelection
          pageSizeOptions={[10, 25, 50]}
          initialState={{
            pagination: {
              paginationModel: { page: 1, pageSize: 10 },
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadIcon: CircularProgress,
          }}
          sx={{ "--DataGrid-overlayHeight": "300px" }}
          onRowClick={(params) => {
            console.log(params.id);
            navigate(`/groups/${params.id}`);
          }}
        />
      </Box>
    </Fragment>
  );
};

export default Groups;
