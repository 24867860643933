import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";

import MediaTab from "./media";
import DocsTab from "./docs";
import LinksTab from "./links";

const Files = (props) => {
  const { chat, close, show } = props;

  const [activeTab, setActiveTab] = useState("media");

  const headerRef = useRef();

  const screen = useSelector((state) => state.screen);
  const screenWidth = screen.screenWidth;

  return (
    <div
      className={`h-full max-h-full flex flex-col overflow-hidden transition-all ${
        show ? "border-l" : "!w-0"
      } ${screenWidth <= 1100 ? "absolute top-0 right-0 z-50" : ""}`}
      style={{
        width: screenWidth <= 1100 ? "100%" : "50%",
        minWidth: show ? "50%" : "0",
        maxWidth: screenWidth <= 1100 ? "100%" : "600px",
        borderColor: "rgba(134,150,160,0.15)",
        backgroundColor: screenWidth <= 1100 ? "#111b21" : "transparent",
      }}
    >
      <div
        className=''
        ref={headerRef}
        style={{
          backgroundColor: "#202c33",
          color: "#e9edef",
        }}
      >
        <div className='p-4'>
          <ArrowBack onClick={close} className='cursor-pointer' />
        </div>

        <div className='grid grid-flow-row grid-cols-3 [&>*]:border-b-4'>
          <div
            onClick={() => {
              setActiveTab("media");
            }}
            className='flex items-center justify-center p-2 cursor-pointer'
            style={{
              color: activeTab === "media" ? "#00a884" : "",
              borderColor: activeTab === "media" ? "#00a884" : "transparent",
            }}
          >
            <p>Media</p>
          </div>
          <div
            onClick={() => {
              setActiveTab("docs");
            }}
            className='flex items-center justify-center p-2 cursor-pointer'
            style={{
              color: activeTab === "docs" ? "#00a884" : "",
              borderColor: activeTab === "docs" ? "#00a884" : "transparent",
            }}
          >
            <p>Docs</p>
          </div>
          <div
            onClick={() => {
              setActiveTab("links");
            }}
            className='flex items-center justify-center p-2 cursor-pointer'
            style={{
              color: activeTab === "links" ? "#00a884" : "",
              borderColor: activeTab === "links" ? "#00a884" : "transparent",
            }}
          >
            <p>Links</p>
          </div>
        </div>
      </div>

      <div
        className='flex-grow'
        style={{
          height: `calc(100% - ${headerRef?.current?.offsetHeight ?? 0}px)`,
        }}
      >
        {activeTab === "media" ? (
          <MediaTab chat={chat} />
        ) : activeTab === "docs" ? (
          <DocsTab chat={chat} />
        ) : activeTab === "links" ? (
          <LinksTab chat={chat} />
        ) : null}
      </div>
    </div>
  );
};

export default Files;
