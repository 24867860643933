import { useState, useEffect } from "react";
import { CircularProgress, Box, Paper, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import axiosInstance from "../utilities/axiosInstance";
import NewPagination from "../components/newPagination";
import { handleCreatedAt } from "../utilities/utilityFunctions";

import { set as setSnackbar } from "../store/snackbarSlice";
import { set as setNotification } from "../store/notificationsSlice";

const MessagesTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { endpoint, startDate, endDate, template } = props;

  const [data, setData] = useState({
    messages: [],
    page: 1,
    maxPage: 1,
  });

  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });
  const [loading, setLoading] = useState(false);
  const [searchFor, setSearchFor] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [filter, setFilter] = useState([]);

  const columns = [
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "customer_code",
      headerName: "CUSTOMER CODE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "text",
      headerName: "TEXT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "error",
      headerName: "ERROR",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        let error = params.value;
        let errorMessage = "";
        let error_details = "";

        try {
          error = JSON.parse(params.value);

          errorMessage = error.message;
          error_details = error.error_data.details;
        } catch (err) {}

        return <Tooltip title={error_details}>{errorMessage}</Tooltip>;
      },
    },
    {
      field: "status",
      headerName: "STATUS",

      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "sent_from",
      headerName: "SENT VIA",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "sent_by",
      headerName: "SENT BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  const getData = async (page = 1) => {
    setLoading(true);

    axiosInstance
      .get(
        `/dashboard/${endpoint}?page=${page}&searchFor=${searchFor}&sort=${JSON.stringify(
          sort
        )}&startDate=${startDate}&endDate=${endDate}${
          template ? `&templateName=${template}` : ""
        }`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, searchFor, template]);

  const getChat = (message) => {
    axiosInstance
      .get(`/chats/chats/${message.chat_id}`)
      .then((res) => {
        navigate("chats");
        dispatch(
          setNotification({
            chat: res.data.data,
            message_id: message.id,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      });
  };

  return (
    <div className='relative'>
      {loading ? (
        <div className='absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-white opacity-50'>
          <CircularProgress />
        </div>
      ) : null}

      <Box
        className='shadow overflow-auto'
        component={Paper}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#243750",
            color: "#ffffff",
            cursor: "default",
          },
        }}
        style={{
          maxWidth: "calc(100vw - 1rem)",
        }}
      >
        <DataGrid
          rowSelection={false}
          slots={{ toolbar: GridToolbar }}
          filterMode='server'
          filterColumns={["name"]}
          sortingMode='server'
          paginationMode='server'
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          columns={columns}
          rows={data?.messages?.map((message) => ({
            name: message?.name,
            phone: message?.phone,
            customer_code: message?.customer_code,
            created_by: message?.created_by,
            text: message?.text,
            error: message?.error,
            status: message?.status,
            sent_from: message?.sent_from,
            sent_by: message?.sent_by,
            createdAt: handleCreatedAt(new Date(message?.createdAt)),
            id: message?.id,
            chat_id: message?.chat_id,
          }))}
          disableRowSelectionOnClick
          autoHeight
          autoPageSize
          onFilterModelChange={(filter) => {
            setFilter(filter.items[0]);
            setSearchFor(filter.quickFilterValues[0]);
          }}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".PrivateSwitchBase-input": {
              height: "23px",
              margin: "10px 13px",
              width: "20px",
            },
            cursor: "pointer",
            minWidth: "1200px",
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          onSortModelChange={(sort) => {
            setSort(sort[0]);
          }}
          onRowClick={(e) => {
            getChat(e.row);
          }}
        />
      </Box>

      {data?.maxPage > 1 && (
        <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
          <NewPagination totalPage={data} fetchData={getData} />
        </div>
      )}
    </div>
  );
};

export default MessagesTable;
