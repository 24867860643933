import {
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import SearchInput from "./SearchInput";
import StatusInput from "./StatusInput";
import React from "react";
import Classes from "./css/table.module.css";

const CustomToolbar = ({
  searchFor,
  handleSearch,
  handleStatusFilter,
  dataPosition,
  extraToolBarButtons,
  service,
  handleFilteredExport,
  filteredExport,
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />

      {extraToolBarButtons?.length > 0
        ? extraToolBarButtons.map((button) => button)
        : null}
      {handleSearch && (
        <div
          className={Classes.SearchBox}
          style={{ border: "none", marginLeft: "auto" }}
        >
          {dataPosition === "orders" && handleStatusFilter && (
            <StatusInput handleStatusFilter={handleStatusFilter} />
          )}
          <SearchInput searchFor={searchFor} handleSearch={handleSearch} />
        </div>
      )}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
