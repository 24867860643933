import {
  Dashboard,
  SupportAgent,
  Campaign,
  FontDownload,
  Groups3,
  People,
  Workspaces,
  ManageAccounts,
} from "@mui/icons-material";

const navLists = [
  {
    path: "/",
    icon: <Dashboard />,
    text: "Dashboard",
  },
  {
    path: "/groups",
    icon: <Workspaces />,
    text: "Groups",
  },
  {
    path: "/chats",
    icon: <SupportAgent />,
    text: "Chats",
  },
  {
    path: "/campaigns",
    icon: <Campaign />,
    text: "Campaigns",
  },
  {
    path: "/requestedTemplates",
    icon: <FontDownload />,
    text: "Requested Templates",
  },
  {
    path: "/customerGroups",
    icon: <Groups3 />,
    text: "Customer Groups",
  },
  {
    path: "/allAgents",
    icon: <People />,
    text: "All Agents",
  },
  {
    path: "/allCustomers",
    icon: <ManageAccounts />,
    text: "All Customers",
  },
];

export default navLists;
