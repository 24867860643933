import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, CircularProgress } from "@mui/material";

import { set as setAuth } from "../store/authSlice";
import { set as setSnackbar } from "../store/snackbarSlice";
import axiosInstance from "../utilities/axiosInstance";

const LogoutButton = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const logout = () => {
    setLoading(true);

    axiosInstance
      .post("/auth/logout")
      .then((res) => {
        dispatch(setAuth({ user: null, authenticated: false }));
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      variant='contained'
      className='text-white'
      onClick={() => {
        if (loading) return;

        logout();
      }}
    >
      {loading ? (
        <CircularProgress size={24} style={{ color: "white" }} />
      ) : (
        "Logout"
      )}
    </Button>
  );
};

export default LogoutButton;
