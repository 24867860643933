import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Backdrop } from "@mui/material";

import C from "../../../containers/customerGroups/customerGroups";
import CreateUpdateGroupViaCSV from "../../../components/createUpdateCustomerGroupViaCsv";

const CustomerGroups = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [fetch, setFetch] = useState(Date.now());

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => {
          setOpen(false);
        }}
      >
        <CreateUpdateGroupViaCSV
          close={() => {
            setOpen(false);
          }}
          setFetch={setFetch}
        />
      </Backdrop>

      <div className='flex items-center justify-between mb-16 flex-wrap'>
        <h3 className='text-lg font-medium'>CustomerGroups</h3>

        <div className='flex items-center gap-4 flex-wrap'>
          <Button
            size='small'
            variant='contained'
            onClick={() => {
              setOpen(true);
            }}
          >
            Create Or Update Customer Group Via CSV
          </Button>

          <Button
            size='small'
            variant='contained'
            onClick={() => {
              navigate("/createCustomerGroup");
            }}
          >
            Create Customer Group
          </Button>
        </div>
      </div>

      <C nav={true} fetch={fetch} />
    </div>
  );
};

export default CustomerGroups;
