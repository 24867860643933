import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

const notifications = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    set: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    reset: (state, action) => ({
      ...initialState,
    }),
  },
});

export const { set } = notifications.actions;

export default notifications.reducer;
