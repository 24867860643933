import { Close, Notifications as NotificationsIcon } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

import { set as setNotification } from "../store/notificationsSlice";
import { useState, useEffect, useRef } from "react";
import axiosInstance from "../utilities/axiosInstance";
import ChatListItem from "./chatListItem";

const Notifications = (props) => {
  const notifications = useSelector((state) => state.notifications);
  const screen = useSelector((state) => state.screen);

  const dispatch = useDispatch();
  const ref = useRef();

  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [chatData, setChatData] = useState(null);

  const getChats = async (page = 1) => {
    if (!notifications.openModal) return;

    setLoading(true);

    axiosInstance
      .get(`/chats/chats?page=${page}&unreadMessages=true`)
      .then((res) => {
        const data = res.data.data;

        if (data.page === 1 || chatData.page < data.page) {
          setChats((prev) => [...(data.page === 1 ? [] : prev), ...data.chats]);
          setChatData({
            page: data.page,
            maxPage: data.maxPage,
            total: data.total,
          });
          dispatch(setNotification({ count: data.total }));
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.openModal, notifications.updateChatsData]);

  const markAllAsRead = () => {
    setLoading(true);
    axiosInstance
      .post("/chats/markAllAsRead")
      .then((res) => {
        dispatch(
          setNotification({
            updateChatsData: Date.now(),
          })
        );
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className='relative cursor-pointer'
      onClick={() => {
        dispatch(
          setNotification({
            openModal: !notifications.openModal,
          })
        );
      }}
    >
      <NotificationsIcon fontSize='large' color='primary' />

      {notifications.count ? (
        <div className='absolute top-0 right-0 rounded-full text-white h-4 w-4 bg-green-800 flex items-center justify-center'>
          <p
            style={{
              fontSize: notifications.count > 99 ? "8px" : "10px",
            }}
          >
            {notifications.count > 99 ? "99+" : notifications.count}
          </p>
        </div>
      ) : null}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "block",
        }}
        open={!!notifications.openModal}
        onClick={() => {
          dispatch(
            setNotification({
              openModal: false,
            })
          );
        }}
      >
        <div className='relative w-full h-full'>
          <div
            className='rounded absolute right-4 p-4'
            style={{
              top: `${screen.headerHeight + 8}px`,
              width: "400px",
              backgroundColor: "#111b21",
              maxWidth: "calc(100vw - 2rem)",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className='flex  items-center justify-between'>
              <p style={{ color: "#00a884" }}>Chats</p>

              <Close
                className='cursor-pointer'
                style={{
                  color: "#d1d7db",
                }}
                onClick={() => {
                  dispatch(
                    setNotification({
                      openModal: false,
                    })
                  );
                }}
              />
            </div>

            {notifications.count > 0 ? (
              <div
                onClick={() => {
                  markAllAsRead();
                }}
              >
                <p
                  className='text-sm cursor-pointer'
                  style={{
                    color: "#00a884",
                  }}
                >
                  Mark All As Read
                </p>
              </div>
            ) : null}

            <div
              className='flex-grow overflow-auto relative mt-2'
              ref={ref}
              style={{
                height: `calc(100vh - ${
                  screen.headerHeight
                }px - 8px - 16px - 24px - ${
                  notifications.count > 0 ? 24 : 0
                }px - 40px)`,
              }}
            >
              {chats.map((chat, index) => (
                <ChatListItem
                  index={index}
                  key={chat.uuid}
                  chatListItem={chat}
                  closeNotification={() => {
                    dispatch(
                      setNotification({
                        openModal: false,
                      })
                    );
                  }}
                />
              ))}

              {loading ? (
                <div className=' flex items-center justify-center p-4'>
                  <CircularProgress size={24} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default Notifications;
