import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Header from "../../containers/header";
import Nav from "../../containers/nav";
import axiosInstance from "../../utilities/axiosInstance";
import { set as setNotification } from "../../store/notificationsSlice";
import { useEffect } from "react";

const Home = () => {
  const screen = useSelector((state) => state.screen);
  const dispatch = useDispatch();

  const getUnreadChatsCount = async () => {
    axiosInstance
      .get("/chats/unreadChatsCount?unreadMessages=true")
      .then((res) => {
        dispatch(setNotification({ count: res?.data?.data ?? 0 }));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getUnreadChatsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component='div' className='flex relative'>
      <Nav />

      <Box component='div' className='flex-grow transition-all'>
        <Header />

        <Box
          component='div'
          className='overflow-auto p-2'
          style={{
            height: `calc(100vh - ${screen.headerHeight}px)`,
          }}
        >
          <Outlet
            context={{
              getUnreadChatsCount,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
