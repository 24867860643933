import { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";

import axiosInstance from "../utilities/axiosInstance";
import Doc from "../components/doc";

const DocsTab = (props) => {
  const { chat } = props;

  const ref = useRef();
  const [data, setData] = useState({
    messages: [],
    page: 1,
    maxPage: 1,
  });

  const [loading, setLoading] = useState(false);
  const getData = async (page = 1) => {
    setLoading(true);

    axiosInstance
      .get(`/chats/messages/${chat.id}?page=${page}&type=docs`)
      .then((res) => {
        setData((prev) => {
          const d = {
            messages: [...prev.messages, ...res.data.data.messages],
            page: res.data.data.page,
            maxPage: res.data.data.maxPage,
          };

          return d;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ref?.current?.addEventListener("scroll", (e) => {
      console.log(ref.current.scrollTop, ref.current.scrollHeight);

      if (!loading && ref.current.scrollTop === 0 && data.page < data.maxPage) {
        getData(data.page + 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='mx-auto p-4 max-h-full h-full overflow-auto flex flex-col gap-2 items-stretch relative'
      ref={ref}
    >
      {loading ? (
        <div className='absolute z-10 left-0 right-0 bottom-0 h-full flex items-center justify-center bg-white bg-opacity-5'>
          <CircularProgress />
        </div>
      ) : null}
      {data.messages.map((message) => (
        <Doc key={message.message_id} message={message} />
      ))}
    </div>
  );
};

export default DocsTab;
