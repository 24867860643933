import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight } from "@mui/icons-material";
import {
  TextField,
  Autocomplete,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";

import axiosInstance from "../../../utilities/axiosInstance";
import Customers from "../../../containers/customers/customers";
import { set as setSnackbar } from "../../../store/snackbarSlice";
import InputPlaceholders from "../../../containers/inputPlaceholders";
import CustomerGroups from "../../../containers/customerGroups/customerGroups";

const SendTemplateToAll = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = useSelector((state) => state.screen.screenWidth);

  const [templates, setTemplates] = useState([]);
  const [temp, setTemp] = useState("");
  const [template, setTemplate] = useState(null);
  const [headerMedia, setHeaderMedia] = useState(null);
  const [headerPlaceholders, setHeaderPlaceholders] = useState([]);
  const [bodyPlaceholders, setBodyPlaceholders] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const [selectTemplate, seSelectTemplate] = useState(true);
  const [selectUsers, setSelectUsers] = useState(false);
  const [selectGroups, setSelectGroups] = useState(false);

  useEffect(() => {
    if (temp) {
      const t = templates.find((e) => e.name === temp);

      setTemplate(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp]);

  const getTemplates = async () => {
    axiosInstance
      .get("/chats/templates")
      .then((res) => {
        setTemplates(res.data?.data ?? []);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    console.log(headerPlaceholders);
    console.log(bodyPlaceholders);

    if (!template) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "First Select a Template!",
        })
      );

      return;
    }

    if (template.header_text) {
      const count = (template.header_text.match(/{{\d}}/g) ?? []).length;

      if (count < headerPlaceholders) {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Enter for all placeholders",
          })
        );

        return;
      }

      for (let i = 0; i < headerPlaceholders.length; i++) {
        if (!headerPlaceholders[i].value) {
          dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: "Enter for all placeholders",
            })
          );

          return;
        }
      }
    }

    if (template.body_text) {
      const count = (template.body_text.match(/{{\d}}/g) ?? []).length;

      if (count < bodyPlaceholders) {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Enter for all placeholders",
          })
        );

        return;
      }

      for (let i = 0; i < bodyPlaceholders.length; i++) {
        if (!bodyPlaceholders[i].value) {
          dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: "Enter for all placeholders",
            })
          );

          return;
        }
      }
    }

    if (selectedUsers.length <= 0 && selectedGroups.length <= 0) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Please select at least one user or group!",
        })
      );

      return;
    }

    if (!campaignName) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Please Enter campaign name!",
        })
      );

      return;
    }

    const data = new FormData();

    data.append("users", JSON.stringify(selectedUsers));
    data.append("groups", JSON.stringify(selectedGroups));
    data.append("bodyPlaceholders", JSON.stringify(bodyPlaceholders));
    data.append("headerPlaceholders", JSON.stringify(headerPlaceholders));
    data.append("campaignName", campaignName);

    if (
      template.header_type === "DOCUMENT" ||
      template.header_type === "IMAGE"
    ) {
      data.append("media", headerMedia);
    }

    setLoading(true);

    axiosInstance
      .post(`/chats/sendTemplateToAll/${template.id}`, data)
      .then((res) => {
        navigate("/campaigns");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className='flex flex-col gap-8'
      style={{
        maxWidth: "calc(100vw - 1rem)",
      }}
    >
      <div
        className='shadow'
        style={{
          maxWidth: "500px",
          padding: screenWidth > 700 ? "2rem" : "0px",
        }}
      >
        <div
          className=' flex items-center gap-4 cursor-pointer border-b p-2'
          onClick={() => {
            seSelectTemplate(!selectTemplate);
          }}
        >
          <ArrowRight
            size={32}
            className={`transition-all ${selectTemplate ? "rotate-90" : ""}`}
          />

          <h3 className='text-lg font-medium'>Select Template</h3>
        </div>

        <div
          className='overflow-hidden flex flex-col gap-3 transition-all'
          style={{
            height: selectTemplate ? "auto" : "0px",
            padding: selectTemplate ? "1rem" : "0px",
          }}
        >
          <Autocomplete
            id='template'
            size='small'
            value={temp}
            onChange={(e, v) => {
              setTemp(v);
            }}
            options={templates.map((e) => {
              return e.name;
            })}
            renderInput={(params) => (
              <TextField {...params} label='Select Template' />
            )}
          />

          {template ? (
            <Fragment>
              <h3>
                Header: <span>{template?.header_type}</span>
              </h3>

              {template?.header_type === "TEXT" ? (
                <Fragment>
                  <p>{template.header_text}</p>
                  <InputPlaceholders
                    text={template.header_text}
                    setPH={setHeaderPlaceholders}
                  />
                </Fragment>
              ) : null}

              {template?.header_type === "DOCUMENT" ||
              template?.header_type === "IMAGE" ? (
                <Fragment>
                  <input
                    type='file'
                    accept={
                      template?.header_type === "DOCUMENT"
                        ? ".pdf, .csv, .xlxs"
                        : template?.header_type === "IMAGE"
                        ? ".png, .jpeg, .jpg, .webp"
                        : ""
                    }
                    label={`Select Media`}
                    onChange={(e) => {
                      setHeaderMedia(e.currentTarget.files[0]);
                    }}
                  />

                  {headerMedia && headerMedia?.size > 5242880 ? (
                    <p className='text-red-600'>{`File size must be atmost ${
                      5242880 / (1024 * 1024)
                    } MB.`}</p>
                  ) : null}
                </Fragment>
              ) : null}

              <Divider></Divider>

              <h3>Body:</h3>

              <p>{template.body_text}</p>

              <InputPlaceholders
                text={template.body_text}
                setPH={setBodyPlaceholders}
              />

              <Divider></Divider>

              {template.footer_text ? (
                <Fragment>
                  <h3>Footer:</h3>

                  <p>{template.footer_text}</p>
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
        </div>
      </div>

      <div className='shadow'>
        <div
          className=' flex items-center gap-4 cursor-pointer border-b p-2'
          onClick={() => {
            setSelectGroups(!selectGroups);
          }}
        >
          <ArrowRight
            size={32}
            className={`transition-all ${selectGroups ? "rotate-90" : ""}`}
          />

          <h3 className='text-lg font-medium'>Select Groups</h3>

          <div className='flex-grow flex items-center justify-end'>
            {selectedGroups.length ? (
              <p>{selectedGroups.length} Groups Selected</p>
            ) : null}
          </div>
        </div>

        <div
          className='overflow-hidden flex flex-col gap-3 transition-all'
          style={{
            height: selectGroups ? "auto" : "0px",
            // padding: selectUsers ? "1rem" : "0px",
          }}
        >
          <CustomerGroups
            select={true}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
          />
        </div>
      </div>

      {/* Select Users */}
      <div className='shadow'>
        <div
          className=' flex items-center gap-4 cursor-pointer border-b p-2'
          onClick={() => {
            setSelectUsers(!selectUsers);
          }}
        >
          <ArrowRight
            size={32}
            className={`transition-all ${selectUsers ? "rotate-90" : ""}`}
          />

          <h3 className='text-lg font-medium'>Select Users</h3>

          <div className='flex-grow flex items-center justify-end'>
            {selectedUsers.length ? (
              <p>{selectedUsers.length} Users Selected</p>
            ) : null}
          </div>
        </div>

        <div
          className='overflow-auto flex flex-col gap-3 transition-all'
          style={{
            height: selectUsers ? "auto" : "0px",
            // padding: selectUsers ? "1rem" : "0px",
          }}
        >
          <Customers
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
      </div>

      <div className='flex items-center gap-4 flex-wrap'>
        <TextField
          size='small'
          id='Campaign Name'
          label='Campaign Name'
          value={campaignName}
          onChange={(e) => {
            setCampaignName(e.target.value);
          }}
        />

        <Button
          variant='contained'
          disabled={loading || (headerMedia && headerMedia?.size > 5242880)}
          onClick={() => {
            submit();
          }}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "Create Campaign"
          )}
        </Button>
      </div>
    </div>
  );
};

export default SendTemplateToAll;
