import { NavLink } from "react-router-dom";
import { Box, Tooltip } from "@mui/material";

import Classes from "./nl.module.scss";

const NL = (props) => {
  const { expanded, nl, hideNav } = props;
  const icon = nl.icon;

  return (
    <NavLink
      to={nl.path}
      className={({ isActive }) =>
        `flex items-center rounded gap-3 ${[
          Classes.NL,
          isActive ? Classes.active : "",
        ].join(" ")}`
      }
      style={{
        aspectRatio: expanded ? "auto" : "1 / 1",
        justifyContent: expanded ? "start" : "center",
        padding: expanded ? "0.25rem" : "0.25rem",
      }}
      onClick={() => {
        hideNav();
      }}
    >
      <Tooltip title={expanded ? "" : nl.text}>
        <Box component='div' className='flex items-end justify-center w-6'>
          {icon}
        </Box>
      </Tooltip>
      {expanded ? <p>{nl.text}</p> : ""}
    </NavLink>
  );
};

export default NL;
