import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  CircularProgress,
  Box,
  Paper,
  TextField,
  Autocomplete,
  Button,
} from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

import CustomToolbar from "../../table/CustomToolbar";
import axiosInstance from "../../utilities/axiosInstance";
import NewPagination from "../../components/newPagination";
import { handleCreatedAt } from "../../utilities/utilityFunctions";
import { set as setSnackbar } from "../../store/snackbarSlice";

const Campaigns = (props) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    campaigns: [],
    page: 1,
    maxPage: 1,
  });

  const [text, setText] = useState("");

  // const [selectedRows, setSelectedRows] = useState([]);

  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });

  const [loading, setLoading] = useState(false);
  const apiRef = useRef(null);

  const stopCampaign = async (index, id) => {
    setLoading(true);

    axiosInstance
      .post(`/chats/stopCampaign/${id}`)
      .then((res) => {
        setData((prev) => {
          const d = { ...prev };

          const cs = d.campaigns;
          cs[index] = res.data.data;

          d.campaigns = cs;

          return d;
        });
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            open: true,
            message: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      flex: 0.5,
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "users",
      headerName: "USERS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            variant='contained'
            size='small'
            disabled={params.value !== "running"}
            onClick={() => {
              stopCampaign(params.row.index, params.row.id);
            }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "created_by",
      headerName: "CREATED BY",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "template_id",
      headerName: "TEMPLATE",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  // eslint-disable-next-line no-unused-vars
  const [statusTypes, setStatusTypes] = useState([
    "running",
    "stopped",
    "completed",
  ]);
  const [status, setStatus] = useState([]);

  const getAllCampaigns = async (page = 1) => {
    setLoading(true);

    if (apiRef.current) {
      apiRef.current.abort();
    }

    apiRef.current = new AbortController();

    const filters = [];

    if (status.length) {
      filters.push({ field: "status", value: status });
    }

    axiosInstance
      .get(
        `/chats/campaigns?page=${page}&sort=${JSON.stringify(
          sort
        )}&text=${text}&filters=${JSON.stringify(filters)}`,
        {
          signal: apiRef.current.signal,
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, text, status]);

  return (
    <div className='relative'>
      {loading ? (
        <div className='absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-white opacity-50'>
          <CircularProgress />
        </div>
      ) : null}

      <div className='my-2 grid grid-flow-row grid-cols-2 gap-4'>
        <TextField
          size='small'
          fullWidth
          id='text-search'
          label='Search'
          placeholder='Search for name, email, phone'
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          style={{
            gridColumn: "1 / -1",
          }}
        />

        <Autocomplete
          id='status'
          multiple
          size='small'
          value={status}
          onChange={(e, v) => setStatus(v)}
          options={statusTypes.map((e) => {
            return e;
          })}
          renderInput={(params) => <TextField {...params} label='Status' />}
        />
      </div>

      <Box
        className='shadow overflow-auto'
        component={Paper}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#243750",
            color: "#ffffff",
            cursor: "default",
          },
        }}
        style={{
          maxWidth: "calc(100vw - 1rem)",
        }}
      >
        <DataGrid
          rows={data?.campaigns?.map((campaign, index) => ({
            name: campaign?.name,
            users: campaign?.users?.length,
            status: campaign?.status,
            created_by: campaign?.created_by,
            template_id: campaign?.template_id,
            createdAt: handleCreatedAt(new Date(campaign?.createdAt)),
            id: campaign?.id,
            index,
          }))}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          filterMode='server'
          sortingMode='server'
          paginationMode='server'
          disableRowSelectionOnClick
          autoHeight
          autoPageSize
          onFilterModelChange={(filter) => {
            // setFilter(filter.items[0]);
            // setSearch(filter.quickFilterValues[0]);
          }}
          sx={{
            minWidth: "800px",
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".PrivateSwitchBase-input": {
              height: "23px",
              margin: "10px 13px",
              width: "20px",
            },
            cursor: "pointer",
          }}
          onRowClick={(params) => {}}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          onSortModelChange={(sort) => {
            setSort(sort[0]);
          }}
        />
      </Box>

      {data?.maxPage > 1 && (
        <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
          <NewPagination totalPage={data} fetchData={getAllCampaigns} />
        </div>
      )}
    </div>
  );
};

export default Campaigns;
