import { Close, Send } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";

const emojis = [
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "😅",
  "🤣",
  "🙂",
  "🙃",
  "😉",
  "😊",
  "😇",
  "🙏",
  "👍",
  "🤚",
];

const Emojis = (props) => {
  const { closeShowEmojis, addToTextV, text, setText, submit } = props;

  return (
    <div
      className='rounded-lg p-4 shadow flex flex-col items-stretch gap-2'
      style={{
        backgroundColor: "#000B17",
        width: "500px",
        maxWidth: "90%",
        height: "400px",
      }}
    >
      <div className='flex items-center justify-end'>
        <Close
          className='text-gray-200 cursor-pointer'
          onClick={() => {
            closeShowEmojis?.();
          }}
        />
      </div>

      <div className='flex-grow'>
        <div className='grid grid-flow-row grid-cols-12 items-start gap-2'>
          {emojis.map((e, i) => {
            return (
              <div key={e + i} className='flex items-center justify-center'>
                <p
                  className='cursor-pointer text-2xl'
                  onClick={() => {
                    addToTextV(e);
                  }}
                >{`${e}`}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className='flex items-center gap-2'>
        <div className='flex-grow'>
          <TextField
            id='text'
            name='text'
            fullWidth
            // multiline
            // maxRows={5}
            required
            placeholder='Type a message'
            style={{
              borderRadius: "8px",
            }}
            inputProps={{
              style: {},
            }}
            InputProps={{
              style: {
                fontSize: "14px",
                fontWeight: "300",
                // padding: "0rem 0.05rem",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  "&:hover fieldset": {},
                  "&.Mui-focused fieldset": {},
                },
              },
            }}
            value={text}
            onChange={(e) => {
              setText(e.currentTarget.value);
            }}
          />
        </div>

        <IconButton
          type='button'
          style={{
            opacity: "0.6",
          }}
          disabled={!text}
          onClick={() => {
            submit();
            closeShowEmojis();
          }}
        >
          <Send />
        </IconButton>
      </div>
    </div>
  );
};

export default Emojis;
