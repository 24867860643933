import { useEffect, useState, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Tooltip,
  IconButton,
  TextField,
  Modal,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import {
  Send,
  Add,
  Mood,
  ArrowBack,
  KeyboardArrowLeft,
  KeyboardArrowDown,
} from "@mui/icons-material";

import axiosInstance from "../utilities/axiosInstance";
import Message from "../components/message";
import SentMedia from "./sentMedia";
import SendTemplate from "./sendTemplate";

import { set as setSnackbar } from "../store/snackbarSlice";
import bgImage from "../assets/bg.png";
import Emojis from "./emojis";
import Files from "./files";
import { handleCreatedAt } from "../utilities/utilityFunctions";
import { set as setNotification } from "../store/notificationsSlice";

import { socket } from "../socket";

const Chat = (props) => {
  const {
    chat,
    activeChatMessage,
    setActiveMessage,
    activeConversation,
    setActiveChatMessage,
    closeChat,
    // eslint-disable-next-line no-unused-vars
    setLastMessage,
  } = props;

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(
    chat.unread_messages_count ?? 0
  );
  const user = useSelector((state) => state.auth.user);
  // eslint-disable-next-line no-unused-vars
  const isSupportAgentService = user.isSupportAgentService;
  const screen = useSelector((state) => state.screen);
  const screenWidth = screen.screenWidth;
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  const [textV, setTextV] = useState("");

  const [loading, setLoading] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [yourGroup, setYourGroup] = useState(false);
  const [isAdminOrSuperAdmin, setIsAdminOrSuperAdmin] = useState(false);
  const [isSupportManager, setIsSupportManager] = useState(false);
  const [yourConversation, setYourConversation] = useState(false);
  const [sendMessageAllow, setSendMessageAllow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [sendTemplateAllow, setSendTemplateAllow] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showConversation, setShowConversation] = useState(false);
  const [bgWidth, setBGWidth] = useState(0);
  const [temporaryAccess, setTemporaryAccess] = useState(null);

  const mediaRef = useRef(null);
  const [media, setMedia] = useState(null);
  const [text, setText] = useState("");

  const headerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const messagesContainer = useRef(null);
  const pageRef = useRef(null);
  const loadingRef = useRef(null);
  const maxPageRef = useRef(null);
  pageRef.current = page;
  loadingRef.current = loading;
  maxPageRef.current = maxPage;
  const formRef = useRef(null);

  const currentMessageRef = useRef();

  const [sendTemplate, setSendTemplate] = useState(null);

  const [showEmojis, setShowEmojis] = useState(false);

  const [ioh, setioh] = useState(20);

  const scrollActionRef = useRef();
  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState(true);

  useEffect(() => {
    // setBGWidth(messagesContainer.current?.offsetWidth ?? 0);

    setTimeout(() => {
      setBGWidth(messagesContainer.current?.offsetWidth ?? 0);
    }, 500);
  }, [messagesContainer, showFiles, showConversation]);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setBGWidth(messagesContainer.current?.offsetWidth ?? 0);
    });
  }, []);

  const scrollToBottom = (time = 500) => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, time);
  };

  const scrollToCurrent = () => {
    setTimeout(() => {
      currentMessageRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, [500]);
  };

  const actionOnScroll = () => {
    console.log(
      messagesContainer.current.scrollTop,
      messagesContainer.current.offsetHeight,
      messagesContainer.current.scrollHeight
    );
    if (
      messagesContainer.current.scrollTop +
        messagesContainer.current.offsetHeight +
        10 >=
      messagesContainer.current.scrollHeight
    ) {
      setShowScrollToBottomButton(false);
    } else {
      setShowScrollToBottomButton(true);
    }

    if (
      !loadingRef.current &&
      messagesContainer.current.scrollTop === 0 &&
      pageRef.current < maxPageRef.current
    ) {
      getMessages(pageRef.current + 1);
    }
  };

  useEffect(() => {
    messagesContainer?.current?.addEventListener("scroll", (e) => {
      clearTimeout(scrollActionRef.current);
      scrollActionRef.current = setTimeout(() => {
        actionOnScroll();
      }, 100);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      page === 1 &&
      chat &&
      notifications.chat &&
      !(notifications.chat?.id === chat?.id && !isNaN(notifications.message_id))
    ) {
      scrollToBottom();
    } else if (
      messages.length > 0 &&
      notifications.chat?.id === chat?.id &&
      !isNaN(notifications.message_id)
    ) {
      if (
        messages[0].id <= notifications.message_id &&
        notifications.message_id <= messages[messages.length - 1].id
      ) {
        scrollToCurrent();
      } else {
        getMessages(pageRef.current + 1);
      }
    }

    // const lastMessage = messages[messages.length - 1];

    // if (lastMessage && lastMessage.type === "sent") {
    //   setLastMessage(chat, lastMessage);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, notifications.chat, notifications.message_id]);

  const closeMedia = () => {
    setMedia(null);
    mediaRef.current.value = "";
    setText("");
  };

  const closeSendTemplate = () => {
    setSendTemplate(false);
  };

  const closeShowEmojis = () => {
    setShowEmojis(false);
  };

  const getMessages = async (page = 1) => {
    setLoading(true);
    axiosInstance
      .get(`/chats/messages/${chat.id}?page=${page}`)
      .then((res) => {
        setPage(res.data.data.page);
        setMaxPage(res.data.data.maxPage);

        if (page === 1) {
          setMessages(res.data.data.messages);
        } else {
          setMessages((prev) => {
            return [...res.data.data.messages, ...prev];
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getLastConversations = async () => {
    axiosInstance
      .get(`/chats/conversations/${chat.id}`)
      .then((res) => {
        setConversation(res.data.data);
      })
      .catch((err) => {});
  };

  const getYourGroups = async () => {
    axiosInstance
      .get(`/groups/yourGroups`)
      .then((res) => {
        setGroups(res.data.data);
      })
      .catch((err) => {});
  };

  const getAllGroups = () => {
    axiosInstance
      .get("/groups")
      .then((res) => {
        setAllGroups(res.data.data.groups);
      })
      .catch((err) => {});
  };

  const getAllAgents = () => {
    axiosInstance
      .post("/groups/agents", {
        groupIds: conversation?.assigned_groups ?? [],
      })
      .then((res) => {
        setAllAgents(res.data.data);
      })
      .catch((err) => {});
  };

  const getTemporaryAccess = () => {
    axiosInstance
      .get(`/chats2/getUserTemporaryAccess?conversation=${conversation?.id}`)
      .then((res) => {
        setTemporaryAccess(res.data.data);
      })
      .catch((err) => {});
  };

  const saveChatToLocalStorage = () => {
    window.localStorage.setItem("activeChat", chat.phone.substring(2));
  };

  useEffect(() => {
    getMessages();
    getLastConversations();
    getYourGroups();
    getAllGroups();
    saveChatToLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  useEffect(() => {
    if (conversation) {
      getAllAgents();
      getYourGroups();
      getTemporaryAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  const [allowEveryoneToRespond, setAllowEveryoneToRespond] = useState(false);
  const getAllowEveryoneToRespond = async () => {
    axiosInstance
      .get("/chats2/allowEveryoneToRespond")
      .then((res) => {
        setAllowEveryoneToRespond(res.data.data.allow);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: err.response?.data?.message,
            severity: "error",
            open: true,
          })
        );
      })
      .finally(() => {});
  };

  useEffect(() => {
    getAllowEveryoneToRespond();
    socket.on(`allowEveryoneToRespond`, (payload) => {
      setAllowEveryoneToRespond(payload);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAdminOrSuperAdmin = user.isAdminOrSuperAdmin;
    const isSupportManager = user.isSupportManager;
    let yourGroup = false;
    let yourConversation = false;

    if (isAdminOrSuperAdmin) {
      yourGroup = true;
      yourConversation = true;
    } else {
      for (let i = 0; i < (conversation?.assigned_groups ?? []).length; i++) {
        for (let j = 0; j < groups.length; j++) {
          if (conversation?.assigned_groups[i] === groups[j].id) {
            yourGroup = true;
            if (isSupportManager) {
              yourConversation = true;
            } else {
              for (
                let k = 0;
                k < (conversation?.assigned_agents ?? []).length;
                k++
              ) {
                if (user.id === conversation?.assigned_agents[k]) {
                  yourConversation = true;
                }
              }
            }
            break;
          }
        }
      }
    }

    setYourGroup(yourGroup);
    setYourConversation(yourConversation || !!temporaryAccess);
    setIsAdminOrSuperAdmin(isAdminOrSuperAdmin);
    setIsSupportManager(isSupportManager);

    setTimeout(() => {
      setSendMessageAllow(
        chat.is_window_open &&
          ((!!conversation &&
            conversation?.status === "open" &&
            user &&
            yourGroup &&
            yourConversation) ||
            allowEveryoneToRespond)
      );
      setSendTemplateAllow(
        (conversation &&
          conversation?.assigned_groups &&
          conversation?.assigned_groups?.length &&
          yourGroup &&
          yourConversation) ||
          (conversation &&
            (!conversation?.assigned_groups ||
              !conversation?.assigned_groups?.length) &&
            isAdminOrSuperAdmin) ||
          (!conversation && (isAdminOrSuperAdmin || isSupportManager))
      );
    }, [500]);
  }, [
    chat,
    conversation,
    user,
    groups,
    temporaryAccess,
    allowEveryoneToRespond,
  ]);

  const assignToGroups = (groupIds) => {
    axiosInstance
      .post(`/chats/conversations/assignGroups/${conversation.id}`, {
        groupIds,
      })
      .then((res) => {
        setConversation(res.data.data);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      });
  };

  const assignToAgents = (agentIds) => {
    axiosInstance
      .post(`/chats/conversations/assignAgents/${conversation.id}`, {
        agentIds,
      })
      .then((res) => {
        setConversation(res.data.data);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      });
  };

  const updateConversations = (status) => {
    axiosInstance
      .post(`/chats/conversations/update/${conversation.id}`, {
        status,
      })
      .then((res) => {
        setConversation(res.data.data);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      });
  };

  const submit = async (event) => {
    event?.preventDefault();

    const text = textV;

    if (!text) return;

    const message = {
      type: "sent",
      sent: true,
      text,
      username: user.username,
      createdAt: new Date(),
      status: "pending",
    };

    setMessages((prev) => [...prev, message]);

    setTextV("");
  };

  const sendMedia = async () => {
    const message = {
      type: "sent",
      sent: true,
      username: user.username,
      createdAt: new Date(),
      status: "pending",
    };

    if (text) {
      message.text = text;
    }

    if (media) {
      message.media = media;
    }

    setMessages((prev) => [...prev, message]);

    closeMedia();
  };

  const updateMessage = (index, message) => {
    setActiveMessage(message);
    setActiveChatMessage(message);
    setMessages((prev) => {
      const msgs = [...prev];
      if (index) {
        msgs[index] = message;
      } else {
        msgs.push(message);
      }
      return msgs;
    });
  };

  const addMessage = (message) => {
    setActiveChatMessage(message);
    setActiveMessage(message);
    setMessages((prev) => {
      const msgs = [...prev];
      msgs.push(message);
      return msgs;
    });
  };

  useEffect(() => {
    if (activeChatMessage && !activeChatMessage.old) {
      addMessage(activeChatMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChatMessage]);

  useEffect(() => {
    if (activeConversation) {
      setConversation(activeConversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation]);

  const addToTextV = (v) => {
    setTextV((prev) => {
      return prev + v;
    });
  };

  useEffect(() => {
    socket.on(`conversation-${chat.id}`, (payload) => {
      setConversation(payload);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && conversation) {
      socket.on(`temporary-access-${user.id}-${conversation.id}`, (payload) => {
        setTemporaryAccess(payload);
      });
    }
  }, [conversation, user]);

  const getUnreadMessage = async () => {
    axiosInstance
      .get(`/chats/getUnreadMessage/${chat.id}`)
      .then((res) => {
        dispatch(
          setNotification({
            chat: chat,
            message_id: res.data.data.id,
          })
        );
      })
      .catch((err) => {});
  };

  return (
    <div className='h-full max-h-full flex items-stretch relative'>
      <div
        className='h-full max-h-full flex flex-col flex-grow'
        style={{
          maxWidth:
            screenWidth <= 700
              ? "100%"
              : screenWidth <= 1100
              ? "800px"
              : showFiles || showConversation
              ? "600"
              : "1200px",
        }}
      >
        <div
          className='flex items-center gap-4 justify-between p-3 w-full flex-wrap'
          style={{
            backgroundColor: "#202c33",
            color: "#e9edef",
          }}
          ref={headerRef}
        >
          <div className='flex items-center gap-4'>
            {screenWidth <= 700 ? (
              <div
                className='text-white cursor-pointer'
                onClick={() => {
                  closeChat?.();
                }}
              >
                <ArrowBack />
              </div>
            ) : null}

            <div
              className='flex items-center gap-4 cursor-pointer'
              onClick={() => {
                setShowFiles(true);
                setShowConversation(false);
              }}
            >
              {screenWidth <= 700 ? null : <Avatar>{chat.name?.[0]}</Avatar>}
              <div>
                <p className=''>{chat.name}</p>
                <p
                  className='font-light text-xs'
                  style={{
                    color: "#8696a0",
                  }}
                >
                  {chat.phone}
                </p>
              </div>
            </div>
          </div>

          <div
            className={`flex items-center gap-4 justify-between ${
              screenWidth < 500 ? "flex-grow" : ""
            }`}
          >
            <div className='flex items-center gap-4'>
              <Button
                type='button'
                variant='contained'
                onClick={() => {
                  setSendTemplate(true);
                }}
              >
                Send Template
              </Button>

              <p
                className={`text-sm font-semibold text-white text-center rounded-full py-1 px-3 ${
                  chat.is_window_open ? "bg-green-600" : "bg-red-600"
                }`}
              >
                {chat.is_window_open ? "Window Open" : "Window Closed"}
              </p>
            </div>

            {conversation && !showConversation && !showFiles ? (
              <div
                className='text-white cursor-pointer'
                onClick={() => {
                  setShowFiles(false);
                  setShowConversation(true);
                }}
              >
                <KeyboardArrowLeft />
              </div>
            ) : null}
          </div>
        </div>

        <Modal
          open={sendTemplate}
          onClose={() => closeSendTemplate()}
          className='flex items-center justify-center'
          children={
            <SendTemplate
              onClose={() => closeSendTemplate()}
              chat={chat}
              addMessage={(msg) => addMessage(msg)}
            />
          }
        />

        <div
          className='absolute left-0 right-0'
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "repeat",
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            opacity: "0.06",
            width: `${
              bgWidth ?? messagesContainer.current?.offsetWidth ?? 0
            }px`,
            height: `${messagesContainer.current?.offsetHeight ?? 0}px`,
            top: `${headerRef.current?.offsetHeight ?? 0}px`,
          }}
        ></div>

        {showScrollToBottomButton ? (
          <div
            className='rounded-full flex items-center justify-center !aspect-square absolute cursor-pointer'
            style={{
              backgroundColor: "#2a3942",
              width: "2.5rem",
              bottom: "5rem",
              right: "1rem",
              zIndex: "1000",
              color: "#ffffff",
            }}
            onClick={() => {
              scrollToBottom(0);
            }}
          >
            <KeyboardArrowDown fontSize='medium' />
          </div>
        ) : null}

        {unreadMessagesCount > 0 ? (
          <div
            className='rounded-md flex items-center justify-center absolute cursor-pointer px-2 py-1'
            style={{
              backgroundColor: "#2a3942",
              bottom: "4rem",
              left: "1rem",
              zIndex: "1000",
              color: "#ffffff",
            }}
            onClick={() => {
              getUnreadMessage();
            }}
          >
            <p className='text-xs'>Go to unread message</p>
          </div>
        ) : null}

        <div
          ref={messagesContainer}
          className='flex-grow w-full overflow-auto flex flex-col gap-3 p-2 z-10'
          style={
            {
              // backgroundColor: "#111b21",
            }
          }
        >
          <div className='flex-grow'></div>
          {loading ? (
            <div className='flex items-center justify-center'>
              <CircularProgress style={{ color: "#f5f5f566" }} size={16} />
            </div>
          ) : null}
          {messages.map((message, index) => (
            <Message
              key={(message.uuid ?? "uuid") + index + "message"}
              message={message}
              chat={chat}
              index={index}
              updateMessage={updateMessage}
              ref={
                message.id === notifications.message_id
                  ? currentMessageRef
                  : null
              }
              setUnreadMessagesCount={setUnreadMessagesCount}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>

        <input
          type='file'
          accept='.pdf, .csv, .xlxs, .png, .mp4, .mp3, .jpeg, .jpg, .webp'
          className='hidden'
          ref={mediaRef}
          onChange={(e) => {
            setMedia(e.currentTarget.files[0]);
          }}
        />

        <Modal
          open={!!media}
          onClose={() => {
            closeMedia();
          }}
          className='flex items-center justify-center'
          children={
            <SentMedia
              media={media}
              text={text}
              setText={(v) => {
                setText(v);
              }}
              closeMedia={() => {
                closeMedia();
              }}
              sendMedia={() => {
                sendMedia();
              }}
            />
          }
        ></Modal>

        <form
          ref={formRef}
          onSubmit={submit}
          className={`p-2 flex gap-4 w-full ${
            ioh > 20 ? "items-end" : "items-center"
          }`}
          style={{
            backgroundColor: "#202c33",
          }}
          disabled={!sendMessageAllow}
        >
          <Tooltip title='Attachment'>
            <IconButton
              style={{
                opacity: "0.6",
              }}
              disabled={!sendMessageAllow}
              onClick={() => {
                mediaRef.current?.click();
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>

          <Modal
            open={showEmojis}
            onClose={() => closeShowEmojis()}
            className='flex items-center justify-center'
            children={
              <Emojis
                closeShowEmojis={() => closeShowEmojis()}
                addToTextV={addToTextV}
                text={textV}
                setText={setTextV}
                submit={submit}
              />
            }
          />

          <div className='flex-grow'>
            <TextField
              disabled={!sendMessageAllow}
              id='text'
              name='text'
              size='medium'
              fullWidth
              multiline
              maxRows={5}
              value={textV}
              onChange={(e) => {
                setioh(e.target.offsetHeight);
                // console.log(e.target.offsetHeight, e.target.value);
                setTextV(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();

                  if (!e.ctrlKey) {
                    const event = new Event("submit", {
                      bubbles: true,
                      cancelable: true,
                    });
                    formRef.current?.dispatchEvent(event);
                  } else {
                    addToTextV("\n");
                    setioh(e.target.offsetHeight);
                  }
                }
              }}
              required
              placeholder='Type a message'
              style={{
                borderRadius: "8px",
              }}
              inputProps={{
                style: {},
              }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
              InputProps={{
                style: {
                  fontSize: "14px",
                  fontWeight: "300",
                  backgroundColor: "#2a3942",
                },
                startAdornment: (
                  <InputAdornment
                    position='start'
                    className={"cursor-pointer"}
                    style={{
                      opacity: "0.6",
                    }}
                  >
                    <IconButton
                      disabled={!sendMessageAllow}
                      onClick={() => {
                        setShowEmojis(true);
                      }}
                    >
                      <Mood />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                    "&:hover fieldset": {},
                    "&.Mui-focused fieldset": {},
                  },
                },
              }}
            />
          </div>

          <IconButton
            type='submit'
            style={{
              opacity: "0.6",
            }}
            disabled={!sendMessageAllow}
          >
            <Send />
          </IconButton>
        </form>
      </div>

      <Fragment>
        <Files chat={chat} close={() => setShowFiles(false)} show={showFiles} />

        {conversation ? (
          <div
            className={`h-full max-h-full overflow-auto flex flex-col gap-4 transition-all ${
              showConversation
                ? "py-4 px-2 border-l overflow-hidden"
                : "p-0 !w-0"
            } ${screenWidth <= 1100 ? "absolute top-0 right-0 z-50" : ""}`}
            style={{
              width: "300px",
              borderColor: "rgba(134,150,160,0.15)",
              backgroundColor: screenWidth <= 1100 ? "#111b21" : "transparent",
            }}
          >
            <div className='text-white cursor-pointer'>
              <ArrowBack
                onClick={() => {
                  setShowConversation(false);
                }}
                className='cursor-pointer'
              />
            </div>
            <FormControl fullWidth>
              <InputLabel id='conversation-status'>
                Conversation Status
              </InputLabel>
              <Select
                labelId='conversation-status'
                id='conversation-status-select'
                value={conversation?.status ?? "closed"}
                label='Conversation Status'
                disabled={
                  !(
                    isAdminOrSuperAdmin ||
                    (isSupportManager && yourGroup) ||
                    yourConversation
                  )
                }
                onChange={(e) => {
                  updateConversations(e.target.value);
                }}
                input={<OutlinedInput notched label={"Conversation Status"} />}
              >
                <MenuItem value={"open"}>Open</MenuItem>
                <MenuItem value={"closed"}>Close</MenuItem>
                <MenuItem value={"resolved"}>Resolved</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id='assign-group'>Assigned to group</InputLabel>
              <Select
                multiple
                labelId='assign-group'
                id='assign-group-select'
                value={conversation?.assigned_groups ?? []}
                label='Groups'
                disabled={
                  !(
                    conversation.status === "open" &&
                    ((isSupportManager && yourGroup) ||
                      (isSupportManager &&
                        (!conversation.assigned_groups ||
                          !conversation.assigned_groups.length)) ||
                      isAdminOrSuperAdmin)
                  )
                }
                onChange={(e) => {
                  console.log(e.target.value);
                  assignToGroups(e.target.value);
                }}
                input={<OutlinedInput notched label={"Assigned to group"} />}
              >
                {allGroups.map((e) => {
                  return (
                    <MenuItem value={e.id} key={"group" + e.name + e.id}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {conversation.assigned_groups &&
            conversation.assigned_groups.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id='assign-agent'>Assigned to agents</InputLabel>
                <Select
                  multiple
                  labelId='assign-agent'
                  id='assign-agent-select'
                  value={conversation?.assigned_agents ?? []}
                  label='Agents'
                  disabled={!yourConversation || !allAgents.length}
                  onChange={(e) => {
                    console.log(e.target.value);
                    assignToAgents(e.target.value);
                  }}
                  input={<OutlinedInput notched label={"Assigned to agents"} />}
                >
                  {allAgents.map((e) => {
                    return (
                      <MenuItem value={e.id} key={"agent" + e.username + e.id}>
                        {e.username}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}

            {temporaryAccess ? (
              <div>
                <TextField
                  value={"Temporary Access"}
                  size='small'
                  disabled
                  fullWidth
                />

                <p className='text-gray-400'>
                  From: {handleCreatedAt(new Date(temporaryAccess.start_date))}
                </p>

                <p className='text-gray-400'>
                  To: {handleCreatedAt(new Date(temporaryAccess.end_date))}
                </p>
              </div>
            ) : null}
          </div>
        ) : null}
      </Fragment>
    </div>
  );
};

export default Chat;
