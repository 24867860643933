import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, CircularProgress, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import axiosInstance from "../../../utilities/axiosInstance";
import { set as setSnackbar } from "../../../store/snackbarSlice";
import CustomNoRowsOverlay from "../../../components/customNoOverlay";

const AllAgents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const apiControllerRef = useRef();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    agents: [],
    page: 1,
    maxPage: 1,
  });
  const [text, setText] = useState("");

  const getAllAgents = async () => {
    apiControllerRef.current?.abort();

    apiControllerRef.current = new AbortController();

    axiosInstance
      .get(`/chats2/allAgents?text=${text}`, {
        signal: apiControllerRef.current?.signal,
      })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: err.response?.data?.message,
            severity: "error",
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const columns = [
    { field: "id", headerName: "ID", hide: true, hidden: true },
    { field: "name", headerName: "NAME", width: 150 },
    { field: "username", headerName: "USERNAME", width: 150 },
    { field: "conversations", headerName: "CONVERSATIONS", width: 150 },
  ];

  return (
    <div
      style={{
        maxWidth: "calc(100vw - 1rem)",
      }}
    >
      <Box
        component='div'
        className='flex items-center justify-between rounded bg-gray-100 p-2 mb-4'
      >
        <p className='font-medium text-lg'>All Agents</p>
      </Box>

      <Box
        component='div'
        style={{
          height: data.agents.length === 0 ? "500px" : "550px",
        }}
      >
        <TextField
          className='!mb-2'
          size='small'
          fullWidth
          id='text-search'
          label='Search'
          placeholder='Search for name, email, phone'
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          style={{
            gridColumn: "1 / -1",
          }}
        />

        <DataGrid
          rowSelection={false}
          loading={loading}
          rows={data.agents}
          columns={columns}
          pageSizeOptions={[10, 25, 50]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadIcon: CircularProgress,
          }}
          sx={{ "--DataGrid-overlayHeight": "300px" }}
          onRowClick={(params) => {
            console.log(params.id);
            navigate(`/allAgents/${params.id}`);
          }}
        />
      </Box>
    </div>
  );
};

export default AllAgents;
