import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import C from "../../../containers/campaigns/campaigns";

const Campaigns = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='flex items-center justify-between mb-16'>
        <h3 className='text-lg font-medium'>Campaigns</h3>

        <Button
          size='small'
          variant='contained'
          onClick={() => {
            navigate("/sendTemplateToAll");
          }}
        >
          Create Campaign
        </Button>
      </div>

      <C />
    </div>
  );
};

export default Campaigns;
