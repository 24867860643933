import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  CircularProgress,
} from "@mui/material";
import {} from "@mui/icons-material";

import axiosInstance from "../../../utilities/axiosInstance";
import { set as setSnackbar } from "../../../store/snackbarSlice";

const RequestTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screenWidth = useSelector((state) => state.screen.screenWidth);

  const [header_type, set_header_type] = useState("");
  const [header_media, set_header_media] = useState(null);

  const header_media_ref = useRef(null);

  const [loading, setLoading] = useState(false);
  const submit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    formData.delete("header_media");

    if (!header_type) {
      formData.delete("header_type");
    }

    if (header_type && header_type !== "TEXT" && header_media) {
      formData.append("media", header_media);
    }

    setLoading(true);

    axiosInstance
      .post("/chats/requestTemplates", formData)
      .then((res) => {
        navigate("/requestedTemplates");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <h3 className='text-lg font-medium'>Request For New Template</h3>

      <form
        onSubmit={submit}
        className={`mt-4 grid grid-flow-row ${
          screenWidth <= 700 ? "grid-cols-1" : "grid-cols-2"
        } gap-8`}
      >
        <TextField
          size='small'
          fullWidth
          id='name'
          label='Name'
          name='name'
          required
          disabled={loading}
        />

        <FormControl fullWidth>
          <InputLabel id='label-language'>Language *</InputLabel>
          <Select
            labelId='label-language'
            size='small'
            id='language'
            name='language'
            required
            disabled={loading}
            input={<OutlinedInput notched label={"Language"} />}
          >
            <MenuItem value='english'>English</MenuItem>
            <MenuItem value='hindi'>Hindi</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id='label-category'>Category *</InputLabel>
          <Select
            labelId='label-category'
            size='small'
            id='category'
            name='category'
            required
            disabled={loading}
            input={<OutlinedInput notched label={"Category"} />}
          >
            <MenuItem value='UTILITY'>Utility</MenuItem>
            <MenuItem value='MARKETING'>Marketing</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id='label-header-type'>Header Type</InputLabel>
          <Select
            labelId='label-header-type'
            size='small'
            id='header_type'
            name='header_type'
            disabled={loading}
            input={<OutlinedInput notched label={"Header Type"} />}
            value={header_type}
            onChange={(e) => {
              set_header_type(e.target.value);
            }}
          >
            <MenuItem value=''>NONE</MenuItem>
            <MenuItem value='TEXT'>Text</MenuItem>
            <MenuItem value='IMAGE'>Image</MenuItem>
            <MenuItem value='DOCUMENT'>Document</MenuItem>
          </Select>
        </FormControl>

        {header_type === "TEXT" ? (
          <TextField
            size='small'
            fullWidth
            id='header_text'
            label='Header Text'
            name='header_text'
            required
            disabled={loading}
            helperText='To use placeholders use {{PLACEHOLDER_SR_NO}} format. ex. {{1}}'
          />
        ) : header_type ? (
          <div
            className='border rounded p-2 pt-3 border-gray-400 relative'
            onClick={() => {
              header_media_ref?.current?.click();
            }}
          >
            <p className='absolute left-4 top-0 -translate-y-1/2 bg-white text-gray-500 px-1 text-xs font-light'>
              Header Media
            </p>
            <input
              onClick={(e) => {
                e.stopPropagation();
              }}
              ref={header_media_ref}
              required
              disabled={loading}
              type='file'
              accept={
                header_type === "DOCUMENT"
                  ? ".pdf, .csv, .xlxs"
                  : header_type === "IMAGE"
                  ? ".png, .jpeg, .jpg, .webp"
                  : ""
              }
              name='header_media'
              onChange={(e) => {
                set_header_media(e.currentTarget.files[0]);
              }}
            />
          </div>
        ) : null}

        {header_type ? <div></div> : null}

        <TextField
          multiline
          minRows={2}
          size='small'
          fullWidth
          id='body_text'
          label='Body Text'
          name='body_text'
          required
          disabled={loading}
          helperText='To use placeholders use {{PLACEHOLDER_SR_NO}} format. ex. {{1}}'
        />

        <TextField
          size='small'
          fullWidth
          id='footer_text'
          label='Footer Text'
          name='footer_text'
          helperText="Can't use placeholder for footer text."
          disabled={loading}
        />

        <div
          className='flex justify-center'
          style={{
            gridColumn: "1 / -1",
          }}
        >
          <Button
            disabled={loading}
            type='submit'
            variant='contained'
            size='small'
            style={{
              width: "120px",
            }}
          >
            {loading ? (
              <CircularProgress size={24} className='!text-white' />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RequestTemplate;
