import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import themes from "./assets/themes";
import { set as setScreen } from "./store/screenSlice";
import { reset as resetSnackbar } from "./store/snackbarSlice";

import { socket } from "./socket";

import AuthLoading from "./pages/auth/authLoading";
import Login from "./pages/auth/login";
import Home from "./pages/home/home";
import Groups from "./pages/home/groups/groups";
import CreateNewGroup from "./pages/home/groups/createNewGroup";
import Group from "./pages/home/groups/group";
import Chats from "./pages/home/chats/chats";
import SendTemplateToAll from "./pages/home/chats/sendTemplateToAll";
import RequestTemplate from "./pages/home/templates/requestTemplate";
import RequestTemplates from "./pages/home/templates/requestTemplates";
import RequestedTemplate from "./pages/home/templates/requestedTemplate";
import Campaigns from "./pages/home/chats/campaigns";
import CustomerGroups from "./pages/home/chats/customerGroups";
import CreateCustomerGroup from "./pages/home/chats/createCustomerGroup";
import CustomerGroup from "./pages/home/chats/customerGroup";
import AllAgents from "./pages/home/chats/allAgents";
import AgentDetail from "./pages/home/chats/agentDetail";
import AllCustomers from "./pages/home/chats/allCustomers";
import Dashboard from "./pages/home/dashboard/dashboard";

function App() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const screen = useSelector((state) => state.screen);
  const snackbar = useSelector((state) => state.snackbar);
  const theme = screen.theme;

  const handleCloseSnackBar = () => {
    dispatch(resetSnackbar());
  };

  console.log("App Rendered");

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setScreen({ screenWidth: window.innerWidth }));
    });

    return () => {
      window.removeEventListener("resize", null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Object.keys(themes[theme]).forEach((key) => {
      document.documentElement.style.setProperty(
        `--${key}`,
        themes[theme][key]
      );
    });
  }, [theme]);

  const muiTheme = createTheme({
    palette: {
      mode: theme,
    },
  });

  const [isSocketConnected, setIsSocketConnected] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      setIsSocketConnected(true);
      console.log("Socket Connected!");
    }

    function onDisconnect() {
      setIsSocketConnected(false);
      console.log("Socket Disconnected!");
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  const unAuthenticatedRoutes = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='*' element={<Navigate to='/login' />} />
    </Routes>
  );

  const authenticatedRoutes = (
    <Routes>
      <Route path='/' element={<Home />}>
        <Route path='' element={<Dashboard />} />
        <Route path='groups' element={<Groups />} />
        <Route path='groups/create-new-group' element={<CreateNewGroup />} />
        <Route path='groups/:id' element={<Group />} />
        <Route
          path='chats'
          element={<Chats isSocketConnected={isSocketConnected} />}
        />
        <Route path='customerGroups' element={<CustomerGroups />} />
        <Route path='customerGroups/:id' element={<CustomerGroup />} />
        <Route path='createCustomerGroup' element={<CreateCustomerGroup />} />
        <Route path='campaigns' element={<Campaigns />} />
        <Route path='sendTemplateToAll' element={<SendTemplateToAll />} />
        <Route path='requestTemplate' element={<RequestTemplate />} />
        <Route path='requestedTemplates' element={<RequestTemplates />} />
        <Route path='requestedTemplates/:id' element={<RequestedTemplate />} />

        <Route path='allAgents' element={<AllAgents />} />
        <Route path='allAgents/:id' element={<AgentDetail />} />

        <Route path='allCustomers' element={<AllCustomers />} />

        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <Snackbar
        open={snackbar?.open}
        autoHideDuration={snackbar?.duration}
        onClose={handleCloseSnackBar}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity={snackbar?.severity ?? "info"}
          sx={{ width: "100%" }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>

      {auth.loading ? (
        <AuthLoading />
      ) : auth.authenticated ? (
        authenticatedRoutes
      ) : (
        unAuthenticatedRoutes
      )}
    </ThemeProvider>
  );
}

export default App;
