import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, Box } from "@mui/material";

import axiosInstance from "../../utilities/axiosInstance";
import { set as setAuth } from "../../store/authSlice";

const AuthLoading = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    axiosInstance
      .get("/auth")
      .then((res) => {
        dispatch(
          setAuth({
            loading: false,
            authenticated: true,
            user: res.data.data,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setAuth({
            loading: false,
          })
        );
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component='div' className='h-screen flex items-center justify-center'>
      <CircularProgress />
    </Box>
  );
};

export default AuthLoading;
