import { useDispatch } from "react-redux";
import { Avatar } from "@mui/material";
import { AccessTime, Done, DoneAll, SmsFailed } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Classes from "./chatListItem.module.scss";
import axiosInstance from "../utilities/axiosInstance";
import { set as setSnackbar } from "../store/snackbarSlice";
import { set as setNotification } from "../store/notificationsSlice";

const ChatListItem = (props) => {
  const { chatListItem, activeChat, selectChat, closeNotification } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const message = chatListItem.message ?? {
    id: chatListItem.lm_id,
    message_id: chatListItem.lm_message_id,
    type: chatListItem.lm_type,
    text: chatListItem.lm_text,
    header_text: chatListItem.header_text,
    status: chatListItem.lm_status,
    error: chatListItem.lm_error,
  };

  let error;
  let icon;
  let text = message.header_text ?? message.text;
  try {
    error = JSON.parse(message.error);
  } catch (err) {
    error = message.error;
  }

  if (message.status === "pending") {
    icon = <AccessTime fontSize='12px' />;
  } else if (message.status === "sent") {
    icon = <Done fontSize='12px' />;
  } else if (message.status === "delivered" || message.status === "read") {
    icon = <DoneAll fontSize='12px' />;
  } else if (message.status === "failed") {
    icon = (
      <Tooltip
        title={
          error?.title ?? error?.message ?? error?.error?.error_data?.details
        }
      >
        <SmsFailed fontSize='12px' />
      </Tooltip>
    );
  }

  const getChat = () => {
    axiosInstance
      .get(`/chats/chats/${chatListItem.id}`)
      .then((res) => {
        if (selectChat) {
          selectChat(res.data.data);
        } else {
          navigate("chats");
          dispatch(
            setNotification({
              chat: res.data.data,
              message_id: null,
            })
          );
          closeNotification();
        }
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      });
  };

  return (
    <div
      className={`flex items-center gap-4 cursor-pointer px-2 py-3 border-b ${
        activeChat?.id === chatListItem.id ? Classes.active : ""
      } ${Classes.ChatListItem}`}
      style={{
        borderColor: "rgba(134,150,160,0.15)",
        color: "#e9edef",
      }}
      onClick={() => {
        getChat();
      }}
    >
      <Avatar>{chatListItem.name?.[0]}</Avatar>
      <div>
        <p className=''>{chatListItem.name}</p>
        <p className='text-xs'>{chatListItem.customer_code}</p>
        <p
          className='text-xs font-light'
          style={{
            color: "#8696a0",
          }}
        >
          {chatListItem.phone}
        </p>

        {text ? (
          <div className='mt-1 flex items-center gap-1'>
            {message.type === "sent" ? icon : null}
            <p className='text-ellipsis text-xs font-light text-gray-300'>
              {text.length > 40 ? text.substring(0, 40) + "..." : text}
            </p>
          </div>
        ) : null}
      </div>

      {+chatListItem.unread_messages_count > 0 ? (
        <div className='flex-grow flex justify-end items-center'>
          <div
            className='aspect-square rounded-full text-white w-6 flex items-center justify-center'
            style={{ backgroundColor: "#00a884" }}
          >
            <p className='text-xs'>{chatListItem.unread_messages_count}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChatListItem;
