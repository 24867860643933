import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import ChatListItem from "../components/chatListItem";
import ChatSearch from "./chatSearch";
import ChatFilter from "../components/chatFilter";
import { CircularProgress } from "@mui/material";

const ChatList = (props) => {
  const {
    chats,
    activeChat,
    selectChat,
    // updateChat,
    filter,
    setFilter,
    getChats,
    loading,
    chatData,
    unreadMessages,
    setUnreadMessages,
    loading2,
  } = props;

  const ref = useRef();
  const chatDataRef = useRef();
  const loadingRef = useRef();

  chatDataRef.current = chatData;
  loadingRef.current = loading;

  const screen = useSelector((state) => state.screen);
  const screenWidth = screen.screenWidth;

  useEffect(() => {
    ref.current?.addEventListener("scroll", (e) => {
      console.log(chatDataRef.current);
      if (
        !loadingRef.current &&
        ref.current.scrollHeight -
          ref.current.offsetHeight -
          ref.current.scrollTop <=
          200 &&
        chatDataRef.current.maxPage > chatDataRef.current.page
      ) {
        getChats(chatDataRef.current.page + 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='pr-2 border-r flex flex-col [&>*]:w-full relative'
      style={{
        width: screenWidth <= 700 ? "100%" : "25%",
        // maxWidth: "400px",
        borderColor: "rgba(134,150,160,0.15)",
      }}
    >
      <ChatFilter
        filter={filter}
        setFilter={setFilter}
        unreadMessages={unreadMessages}
        setUnreadMessages={setUnreadMessages}
      />
      <ChatSearch
        activeChat={activeChat}
        selectChat={selectChat}
        filter={filter}
        setFilter={setFilter}
        unreadMessages={unreadMessages}
        setUnreadMessages={setUnreadMessages}
      />

      <div className='flex-grow overflow-auto relative' ref={ref}>
        {loading2 ? (
          <div
            className=' flex items-center justify-center p-4 absolute top-0 left-0 right-0 bottom-0'
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.04)",
              backdropFilter: "blur(1px)",
            }}
          >
            <CircularProgress size={24} />
          </div>
        ) : null}
        {chats.map((chat, index) => (
          <ChatListItem
            index={index}
            key={chat.uuid}
            chatListItem={chat}
            activeChat={activeChat}
            selectChat={selectChat}
            // updateChat={updateChat}
          />
        ))}
        {loading && !loading2 ? (
          <div
            className=' flex items-center justify-center p-4'
            // style={{
            //   backgroundColor: "rgba(0, 0, 0, 0.6)",
            //   backdropFilter: "blur(1px)",
            // }}
          >
            <CircularProgress size={24} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChatList;
