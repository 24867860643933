import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ArrowRight } from "@mui/icons-material";
import { TextField, Button, CircularProgress, Alert } from "@mui/material";

import axiosInstance from "../../../utilities/axiosInstance";
import Customers from "../../../containers/customers/customers";
import { set as setSnackbar } from "../../../store/snackbarSlice";

const CustomerGroup = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [selectUsers, setSelectUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (selectedUsers.length <= 0) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Please select at least one user!",
        })
      );

      return;
    }

    if (!name) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Please Enter name!",
        })
      );

      return;
    }

    const data = {
      name: name,
      users: selectedUsers,
    };

    setLoading(true);

    axiosInstance
      .post(`/customers/customerGroups/${id}`, data)
      .then((res) => {
        navigate("/customerGroups");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loading0, setLoading0] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const getData = async () => {
    setLoading0(true);
    setError(null);

    axiosInstance
      .get(`/customers/customerGroups/${id}`)
      .then((res) => {
        const data = res.data.data;
        setName(data.name);
        setSelectedUsers(data.users ?? []);
        setData(data);
      })
      .catch((err) => {
        setError(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading0(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [loading1, setLoading1] = useState(false);
  const deleteCustomerGroup = async () => {
    setLoading1(true);

    axiosInstance
      .delete(`/customers/customerGroups/${id}`)
      .then((res) => {
        dispatch(
          setSnackbar({
            severity: "success",
            message: "Successfully deleted the customer group!",
            open: true,
          })
        );

        navigate("/customerGroups");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading1(false);
      });
  };

  return loading0 ? (
    <div className='p-20 flex items-center justify-center'>
      {<CircularProgress />}
    </div>
  ) : error ? (
    <div>
      <Alert severity='error'>{error}</Alert>
    </div>
  ) : data ? (
    <div className='p-8 flex flex-col gap-8'>
      {/* Select Users */}
      <div className='shadow'>
        <div
          className=' flex items-center gap-4 cursor-pointer border-b p-2'
          onClick={() => {
            setSelectUsers(!selectUsers);
          }}
        >
          <ArrowRight
            size={32}
            className={`transition-all ${selectUsers ? "rotate-90" : ""}`}
          />

          <h3 className='text-lg font-medium'>Select Users</h3>

          <div className='flex-grow flex items-center justify-end'>
            {selectedUsers.length ? (
              <p>{selectedUsers.length} Users Selected</p>
            ) : null}
          </div>
        </div>

        <div
          className='overflow-hidden flex flex-col gap-3 transition-all'
          style={{
            height: selectUsers ? "auto" : "0px",
            // padding: selectUsers ? "1rem" : "0px",
          }}
        >
          <Customers
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
      </div>

      <div className='flex items-center gap-8'>
        <TextField
          size='small'
          id='Name'
          label='Name'
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <Button
          variant='contained'
          disabled={loading}
          onClick={() => {
            submit();
          }}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "Update Customer Group"
          )}
        </Button>
      </div>

      <div className='flex items-center'>
        <Button
          variant='contained'
          disabled={loading1}
          className='!bg-red-600'
          onClick={() => {
            deleteCustomerGroup();
          }}
        >
          {loading1 ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "Delete Customer Group"
          )}
        </Button>
      </div>
    </div>
  ) : null;
};

export default CustomerGroup;
