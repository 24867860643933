import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import axiosInstance from "../../../utilities/axiosInstance";
import { set as setSnackbar } from "../../../store/snackbarSlice";

const CreateNewUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenWidth = useSelector((state) => state.screen.screenWidth);

  const [supportManagers, setSupportManagers] = useState([]);
  const [supportAgents, setSupportAgents] = useState([]);

  const [selectSupportManager, setSelectSupportManager] = useState([]);
  const [selectSupportAgents, setSelectSupportAgents] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const data = {
      name: formData.get("name"),
      default: formData.get("default") ? true : false,
      supportManagers: supportManagers,
      supportAgents: supportAgents,
    };

    setLoading(true);

    axiosInstance
      .post("/groups", data)
      .then((res) => {
        navigate("/groups");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            message: err.response?.data?.message,
            severity: "error",
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSupportManagers = async () => {
    axiosInstance
      .get(`/users/getAllSupports?role=support-manager`)
      .then((res) => {
        setSelectSupportManager(res.data.data);
      })
      .catch((err) => {});
  };

  const getSupportAgents = async () => {
    axiosInstance
      .get(`/users/getAllSupports?role=support-agent`)
      .then((res) => {
        setSelectSupportAgents(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSupportManagers();
    getSupportAgents();
  }, []);

  return (
    <Fragment>
      <Box
        component='div'
        className='flex items-center justify-between rounded bg-gray-100 p-2 mb-4'
      >
        <p className='font-medium text-lg'>Create New Group</p>
      </Box>

      <Box component='form' onSubmit={handleSubmit}>
        <Box
          component='div'
          className={`grid grid-flow-row ${
            screenWidth <= 700 ? "grid-cols-1" : "grid-cols-2"
          } gap-2`}
        >
          <TextField
            margin='normal'
            required
            fullWidth
            id='name'
            label='Name'
            name='name'
            autoComplete='name'
            autoFocus
          />

          <FormControlLabel
            label='Default Group'
            control={
              <Checkbox id='default' name='default' label='Default Group' />
            }
          />

          <Autocomplete
            id='supportManager'
            multiple
            value={supportManagers}
            onChange={(e, v) => setSupportManagers(v)}
            options={selectSupportManager.map((e) => {
              return e.username;
            })}
            renderInput={(params) => (
              <TextField {...params} label='Support Manager' />
            )}
          />

          <Autocomplete
            id='supportAgents'
            multiple
            value={supportAgents}
            onChange={(e, v) => {
              console.log(v);
              setSupportAgents(v);
            }}
            options={selectSupportAgents.map((e) => {
              return e.username;
            })}
            renderInput={(params) => (
              <TextField {...params} label='Support Agents' />
            )}
          />
        </Box>

        <Box component='div' className='flex justify-center' sx={{ mt: 3 }}>
          <Button type='submit' variant='contained' disabled={loading}>
            {loading ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CreateNewUser;
