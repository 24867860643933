import { useState, useEffect } from "react";
import { CircularProgress, Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import CustomToolbar from "../table/CustomToolbar";
import axiosInstance from "../utilities/axiosInstance";
import NewPagination from "../components/newPagination";
import { handleCreatedAt } from "../utilities/utilityFunctions";

const UnAssignedChats = (props) => {
  const { startDate, endDate } = props;

  const [data, setData] = useState({
    chats: [],
    page: 1,
    maxPage: 1,
  });

  const [sort, setSort] = useState({
    field: "conversation_created_at",
    sort: "desc",
  });

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "customer_code",
      headerName: "CUSTOMER CODE",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "conversation_created_at",
      headerName: "CONVERSATION CREATED AT",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "conversation_status",
      headerName: "CONVERSATION STATUS",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  const getData = async (page = 1) => {
    setLoading(true);

    axiosInstance
      .get(
        `/dashboard/unAssignedChats?startDate=${startDate}&endDate=${endDate}&page=${page}&sort=${JSON.stringify(
          sort
        )}`
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  return (
    <div className='relative'>
      {loading ? (
        <div className='absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-white opacity-50'>
          <CircularProgress />
        </div>
      ) : null}

      <Box
        className='shadow overflow-auto'
        component={Paper}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#243750",
            color: "#ffffff",
            cursor: "default",
          },
        }}
        style={{
          maxWidth: "calc(100vw - 1rem)",
        }}
      >
        <DataGrid
          rows={data?.chats?.map((chat) => ({
            name: chat?.name,
            phone: chat?.phone,
            customer_code: chat?.customer_code,
            conversation_status: chat?.conversation_status,
            conversation_created_at: handleCreatedAt(
              new Date(chat?.conversation_created_at)
            ),
            id: chat?.id,
          }))}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          filterMode='server'
          sortingMode='server'
          paginationMode='server'
          disableRowSelectionOnClick
          autoHeight
          autoPageSize
          onFilterModelChange={(filter) => {
            // setFilter(filter.items[0]);
            // setSearch(filter.quickFilterValues[0]);
          }}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".PrivateSwitchBase-input": {
              height: "23px",
              margin: "10px 13px",
              width: "20px",
            },
            cursor: "pointer",
            minWidth: "700px",
          }}
          onRowClick={(params) => {}}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          onSortModelChange={(sort) => {
            setSort(sort[0]);
          }}
        />
      </Box>

      {data?.maxPage > 1 && (
        <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
          <NewPagination totalPage={data} fetchData={getData} />
        </div>
      )}
    </div>
  );
};

export default UnAssignedChats;
