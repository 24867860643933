import { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";

const InputPlaceHolder = (props) => {
  const { index, set } = props;

  const [valueType, setValueType] = useState("SELECT");
  const [valueTypes] = useState(["TEXT", "SELECT"]);

  const [value, setValue] = useState("");
  const [values] = useState([
    "first_name",
    "last_name",
    "display_name",
    "phone",
  ]);

  useEffect(() => {
    set({ index, value, valueType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueType, value, index]);

  return (
    <div className='flex flex-col gap-2'>
      <Autocomplete
        size='small'
        value={valueType}
        onChange={(e, v) => {
          setValueType(v);
        }}
        options={valueTypes.map((e) => {
          return e;
        })}
        fullWidth={false}
        className='bg-gray-200'
        renderInput={(params) => (
          <TextField {...params} label='Select Value Type' />
        )}
      />
      {valueType === "SELECT" ? (
        <Autocomplete
          size='small'
          value={value}
          onChange={(e, v) => {
            setValue(v);
          }}
          options={values.map((e) => {
            return e;
          })}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label='Select Value' />
          )}
        />
      ) : (
        <TextField
          size='small'
          type='text'
          label='Enter Value'
          value={value}
          fullWidth
          onChange={(event) => {
            setValue(event.target.value);
          }}
        />
      )}
    </div>
  );
};

export default InputPlaceHolder;
