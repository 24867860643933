import InputPlaceHolder from "./inputPlaceHolder";

const InputPlaceholders = (props) => {
  const { text, setPH } = props;

  const count = (text.match(/{{\d}}/g) ?? []).length;

  const set = (v) => {
    setPH((prev) => {
      const p = prev;
      p[v.index] = {
        valueType: v.valueType,
        value: v.value,
      };

      return p;
    });
  };

  return (
    <div>
      {count > 0 ? (
        <div className='flex flex-col gap-4'>
          {[...Array(count).keys()].map((_, i) => (
            <InputPlaceHolder key={i} index={i} set={set} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InputPlaceholders;
