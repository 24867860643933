import { Fragment, useEffect, useState, forwardRef, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Launch,
  AccessTime,
  Done,
  DoneAll,
  SmsFailed,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Linkify from "linkify-react";
import { Document, Page, pdfjs } from "react-pdf";

import axiosInstance from "../utilities/axiosInstance";
import { set as setSnackbar } from "../store/snackbarSlice";
import { handleCreatedAt, capitalize } from "../utilities/utilityFunctions";
import { socket } from "../socket";
import { set as setNotification } from "../store/notificationsSlice";
import { setChatsData, setChatsSearchData } from "../store/chatsSlice";

const Message = forwardRef((props, ref) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const chats = useSelector((state) => state.chats.chatsData.chats);
  const chatsRef = useRef();
  chatsRef.current = chats;
  const chatsSearch = useSelector((state) => state.chats.chatsSearchData.chats);
  const chatsSearchRef = useRef();
  chatsSearchRef.current = chatsSearch;

  // eslint-disable-next-line no-unused-vars
  const { message, chat, index, updateMessage, setUnreadMessagesCount } = props;
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [read, setRead] = useState(message.read);
  let isImage = false;
  let isPDF = false;

  const [resending, setResending] = useState(false);

  if (message.media && typeof message.media === "string") {
    const exts = message.media.split(".");

    const l = exts[exts.length - 1];

    if (l === "pdf") {
      isPDF = true;
    } else if (l === "png" || l === "jpeg" || l === "jpg" || l === "webp") {
      isImage = true;
    }
  }

  const sendMessage = async (data) => {
    axiosInstance
      .post(`/chats/messages/${chat.id}`, data)
      .then((res) => {
        updateMessage(index, { ...res.data.data, old: true });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (message.sent) {
      const formData = new FormData();
      formData.append("media", message.media);
      delete message.media;
      formData.append("message", JSON.stringify(message));
      formData.append("phone", chat.phone);
      sendMessage(formData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMedia = () => {
    axiosInstance
      .get(`/chats/media/${chat.id}/${message.id}`)
      .then((res) => {
        window.open(res.data.data, "_blank");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err.response?.data?.message,
            open: true,
          })
        );
      });
  };

  const getFile = async () => {
    axiosInstance
      .get(`/chats/media/${chat.id}/${message.id}`)
      .then((res) => {
        setFile(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (message.media) {
      getFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (message.type === "sent" && message.message_id) {
      socket.on(message.message_id, (payload) => {
        updateMessage(index, { ...payload, old: true });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  let icon = null;

  let error;
  try {
    error = JSON.parse(message.error);
  } catch (err) {
    error = message.error;
  }

  if (message.status === "pending") {
    icon = <AccessTime fontSize='12px' />;
  } else if (message.status === "sent") {
    icon = <Done fontSize='12px' />;
  } else if (message.status === "delivered" || message.status === "read") {
    icon = <DoneAll fontSize='12px' />;
  } else if (message.status === "failed") {
    icon = (
      <Tooltip
        title={(
          error?.title ??
          error?.message ??
          error?.error?.error_data?.details
        )?.toString()}
      >
        <SmsFailed fontSize='12px' />
      </Tooltip>
    );
  }

  const updateChat = (unread_messages_count) => {
    const index = chatsRef.current.findIndex((e) => e.id === chat.id);

    if (index < 0) return;

    const chs = [...chatsRef.current];
    const ch = { ...chs[index] };
    ch.unread_messages_count = unread_messages_count;
    chs[index] = ch;

    dispatch(
      setChatsData({
        chats: chs,
      })
    );
  };

  const updateChatSearch = (unread_messages_count) => {
    const index = chatsSearchRef.current.findIndex((e) => e.id === chat.id);

    if (index < 0) return;

    const chs = [...chatsSearchRef.current];
    const ch = { ...chs[index] };
    ch.unread_messages_count = unread_messages_count;
    chs[index] = ch;

    dispatch(
      setChatsSearchData({
        chats: chs,
      })
    );
  };

  const markAsRead = async () => {
    axiosInstance
      .post(`/chats/markAsRead/${message.message_id}`)
      .then((res) => {
        setRead(true);
        updateChat(res.data.data.unreadMessageCount);
        updateChatSearch(res.data.data.unreadMessageCount);
        setUnreadMessagesCount(res.data.data.unreadMessageCount);
        dispatch(
          setNotification({
            count: res.data.data.unreadChatCounts,
          })
        );
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (message.type === "received" && read === false) {
      markAsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function useIsInViewport(ref) {
  //   const [isIntersecting, setIsIntersecting] = useState(false);

  //   const observer = useMemo(
  //     () =>
  //       new IntersectionObserver(([entry]) =>
  //         setIsIntersecting(entry.isIntersecting)
  //       ),
  //     []
  //   );

  //   useEffect(() => {
  //     observer?.observe(ref.current);

  //     return () => {
  //       observer?.disconnect();
  //     };
  //   }, [ref, observer]);

  //   if (isIntersecting && read === false) {
  //     // console.log(`${message.id} ${isIntersecting}`, "isIntersecting");
  //     markAsRead();
  //   }

  //   return isIntersecting;
  // }

  // useIsInViewport(ref);

  let contacts;

  try {
    contacts = JSON.parse(message.contacts);
  } catch (err) {
    console.log(err);
  }

  let location;

  try {
    location = JSON.parse(message.location);
  } catch (err) {
    console.log(err);
  }

  const resendMessage = async (data) => {
    setResending(true);
    axiosInstance
      .post(`/chats/resend/${message.id}`)
      .then((res) => {
        dispatch(
          setSnackbar({
            severity: "success",
            message: "Successfully resent message!",
            open: true,
          })
        );
        updateMessage(null, { ...res.data.data, old: true });
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err.response?.data?.message,
            open: true,
          })
        );
      })
      .finally(() => {
        setResending(false);
      });
  };

  return (
    <div
      ref={ref}
      className={`w-full flex items-center px-2 ${
        message.type === "sent" ? "justify-end" : "justify-start"
      }`}
      style={{
        backgroundColor: ref ? "#e9edef0d" : "",
      }}
    >
      <div
        className='px-1 py-1 text-sm min-w-40 relative'
        style={{
          color: "#e9edff",
          backgroundColor: message.type === "sent" ? "#005c4b" : "#202c33",
          borderRadius: `${message.type === "sent" ? "4px" : "0px"} ${
            message.type === "sent" ? "0px" : "4px"
          } 4px 4px`,
          // width: "75%",
          maxWidth: "500px",
        }}
      >
        <div
          className={`w-3 h-2 absolute top-0 ${
            message.type === "sent" ? "left-full" : "right-full"
          }`}
          style={{
            backgroundColor: message.type === "sent" ? "#005c4b" : "#202c33",
            clipPath:
              message.type === "sent"
                ? "polygon(0 0, 100% 0, 0 100%)"
                : "polygon(0 0, 100% 0, 100% 100%)",
          }}
        ></div>

        {message.sent_by ? (
          <p className='text-xs font-medium' style={{ color: "#53a6fd" }}>
            {message.sent_by}
          </p>
        ) : null}

        {message.header_text ? (
          <p className='font-medium'>{message.header_text}</p>
        ) : null}
        {message.media && file ? (
          <div
            className='flex items-center justify-between gap-2 rounded overflow-hidden cursor-pointer'
            onClick={() => {
              getMedia();
            }}
          >
            {isImage ? (
              <img
                src={file}
                alt={message.filename ?? "Media"}
                className='max-w-60 object-contain'
                style={{
                  height: "400px",
                }}
              />
            ) : isPDF ? (
              <div className='h-16 overflow-hidden'>
                <Document file={file} className='-mt-8 max-w-full'>
                  <Page pageNumber={1} />
                </Document>
              </div>
            ) : message.message_type === "audio" ? (
              <video
                controls
                name={message.message_id + "-media"}
                className={`${
                  message.message_type === "audio" ? "h-14" : ""
                } w-80`}
              >
                <source src={file} type='audio/ogg' />
              </video>
            ) : (
              <Fragment>
                <p>{message.filename ?? capitalize(message.message_type)}</p>

                <Launch
                  className='cursor-pointer'
                  onClick={() => {
                    getMedia();
                  }}
                />
              </Fragment>
            )}
          </div>
        ) : null}
        {message.text ? (
          <Linkify
            options={{
              className: "link-color text-wrap break-words overflow-hidden",
              target: "_blank",
            }}
          >
            {message.text}
          </Linkify>
        ) : null}

        {message.type === "received" && error ? (
          <p className='text-sm text-red-600'>{error.error_data.details}</p>
        ) : null}

        {contacts
          ? contacts.map((contact, index) => {
              return (
                <div className={`${index !== 0 ? "mt-4" : ""}`}>
                  <p>{contact.name.formatted_name}</p>
                  {contact.phones.map((phone) => {
                    return (
                      <p className='text-xs' style={{ color: "#53bdeb" }}>
                        {phone.phone}
                      </p>
                    );
                  })}
                </div>
              );
            })
          : null}

        {location ? (
          <a
            className='p-4 cursor-pointer block'
            href={`https://google.com/maps/place/${location.latitude},${location.longitude}`}
            rel='noreferrer'
            target='_blank'
          >
            <p className='font-medium' style={{ color: "#53bdeb" }}>
              View Location
            </p>
          </a>
        ) : null}

        {message.footer_text ? (
          <p className='font-light'>{message.footer_text}</p>
        ) : null}

        <div
          className='flex items-end justify-end gap-1 mt-2'
          style={{ color: "#ffffff99" }}
        >
          {message.read_by ? (
            <div className='flex-grow mr-4 flex flex-col'>
              <p
                className='my-0'
                style={{ fontSize: "10px", lineHeight: "0.5" }}
              >
                Read By
              </p>
              <p className='text-xs font-medium' style={{ color: "#53a6fd" }}>
                {message.read_by}
              </p>
            </div>
          ) : null}

          {message.type === "sent" && message.resend ? (
            <div className='flex-grow mr-4 flex flex-col'>
              <button
                className='text-xs font-medium outline-none border-none bg-transparent italic w-fit'
                style={{ color: "#53a6fd" }}
                onClick={resendMessage}
                disabled={resending}
              >
                {resending ? "Resending ...." : "Resend"}
              </button>
            </div>
          ) : null}

          {message.createdAt ? (
            <p style={{ fontSize: "10px" }}>
              {handleCreatedAt(new Date(message.createdAt))}
            </p>
          ) : null}

          {message.type === "sent" ? (
            <div
              className={`${message.status === "failed" ? "text-red-600" : ""}`}
              style={{
                color: message.status === "read" ? "#53bdeb" : "",
              }}
            >
              {icon}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default Message;
