import { Link as L } from "react-router-dom";

import { handleCreatedAt } from "../utilities/utilityFunctions";

const Link = (props) => {
  const { message } = props;

  return (
    <div
      className='p-4 pb-1 gap-1 cursor-pointer flex flex-col relative ml-3'
      style={{
        color: "#e9edff",
        backgroundColor: message.type === "sent" ? "#005c4b" : "#202c33",
        borderRadius: "0 4px 4px 4px",
      }}
    >
      <div
        className={`w-3 h-2 absolute top-0 right-full`}
        style={{
          backgroundColor: message.type === "sent" ? "#005c4b" : "#202c33",
          clipPath: "polygon(0 0, 100% 0, 100% 100%)",
        }}
      ></div>

      <div className='flex flex-col gap-1'></div>
      {message.links.map((l, index) => {
        let link = l;

        if (!link.startsWith("http")) {
          link = `http://${link}`;
        }

        return (
          <L
            className='text-sm text-wrap break-words'
            style={{ color: "#53bdeb" }}
            key={message.message_id + index}
            to={link}
            target='_blank'
          >
            {l}
          </L>
        );
      })}
      <div
        className='flex items-center justify-end gap-1'
        style={{ color: "#ffffff99" }}
      >
        {message.createdAt ? (
          <p style={{ fontSize: "10px" }}>
            {handleCreatedAt(new Date(message.createdAt))}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Link;
