import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  severity: "info",
  duration: 3000,
  open: false,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: initialState,
  reducers: {
    set: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    reset: (state, action) => ({
      ...initialState,
    }),
  },
});

export const { set, reset } = snackbarSlice.actions;

export default snackbarSlice.reducer;
