import { useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
// import { CategoryScale, Chart } from "chart.js";
import "chart.js/auto";

// Chart.register(CategoryScale);

const BarChartComponent = (props) => {
  const {
    labels,
    label,
    data,
    title,
    options = {},
    isHorizontal = false,
    onClickBar,
    title2,
  } = props;
  const chartRef = useRef();

  const plugins = [
    {
      beforeDraw: function (chart) {
        if (chart.chartArea) {
          var ctx = chart.ctx;
          var chartArea = chart.chartArea;
          var barArray = chart.getDatasetMeta(0).data;

          ctx.fillStyle = "#EEE";

          for (let i = 0; i < barArray.length; i++) {
            if (!isHorizontal) {
              const { x, width } = barArray[i];
              ctx.fillRect(
                x - width / 2,
                chartArea.top,
                width,
                chartArea.bottom - chartArea.top
              );
            } else {
              const { y, height } = barArray[i];
              ctx.fillRect(
                chartArea.right,
                y - height / 2,
                chartArea.left - chartArea.right,
                height
              );
            }
          }
        }
      },
    },
  ];

  const onClick = (event) => {
    onClickBar?.(getElementAtEvent(chartRef.current, event)[0].index, title2);
  };

  return (
    <>
      <Bar
        ref={chartRef}
        height={"100%"}
        width={"100%"}
        redraw={true}
        data={{
          labels: labels,
          datasets: [
            {
              label: label,
              backgroundColor: "rgba(255, 98, 0,0.7)",
              borderColor: "rgba(255, 98, 0,1)",
              borderWidth: 1,
              borderRadius: 5,
              hoverBackgroundColor: "rgba(255, 98, 0,0.4)",
              hoverBorderColor: "rgba(255, 98, 0,1)",
              data: data,
            },
          ],
        }}
        options={{
          ...options,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: `${title}`,
              color: "rgba(61, 58, 58, 1)",
              position: "top",
              align: "center",
              font: {
                weight: "bold",
                size: "18px",
              },
              padding: 8,
              fullSize: true,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              beginAtZero: false,
              ticks: {
                color: "rgba(23, 21, 21,1)",
                font: {
                  weight: "bold",
                },
              },
            },
            y: {
              grid: {
                display: false,
              },
              beginAtZero: true,
              ticks: {
                color: "rgba(23, 21, 21,1)",
                font: {
                  weight: "bold",
                },
              },
            },
          },
        }}
        plugins={plugins}
        onClick={onClick}
      />
    </>
  );
};

export default BarChartComponent;
