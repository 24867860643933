import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Paper, TextField } from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

import CustomToolbar from "../../table/CustomToolbar";
import axiosInstance from "../../utilities/axiosInstance";
import NewPagination from "../../components/newPagination";

const CustomerGroups = (props) => {
  const { nav, select, setSelectedGroups } = props;

  const navigate = useNavigate();

  const [data, setData] = useState({
    customerGroups: [],
    page: 1,
    maxPage: 1,
  });

  const [text, setText] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });

  const [loading, setLoading] = useState(false);
  const apiRef = useRef(null);

  const columns = [
    {
      flex: 0.5,
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "users",
      headerName: "USERS",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  useEffect(() => {
    setSelectedGroups?.(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const getAllCustomerGroups = async (page = 1) => {
    setLoading(true);

    if (apiRef.current) {
      apiRef.current.abort();
    }

    apiRef.current = new AbortController();

    const filters = [];

    axiosInstance
      .get(
        `/customers/customerGroups?page=${page}&sort=${JSON.stringify(
          sort
        )}&text=${text}&filters=${JSON.stringify(filters)}`,
        {
          signal: apiRef.current.signal,
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCustomerGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, text, props.fetch]);

  return (
    <div className='relative'>
      {loading ? (
        <div className='absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-white opacity-50'>
          <CircularProgress />
        </div>
      ) : null}

      <div
        className={`my-2 grid grid-flow-row grid-cols-2 gap-4 ${
          select ? "px-2" : ""
        }`}
      >
        <TextField
          size='small'
          fullWidth
          id='text-search'
          label='Search'
          placeholder='Search for name, email, phone'
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          style={{
            gridColumn: "1 / -1",
          }}
        />
      </div>

      <Box
        className='shadow'
        component={Paper}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#243750",
            color: "#ffffff",
            cursor: "default",
          },
        }}
      >
        <DataGrid
          rows={data?.customerGroups?.map((customerGroup) => ({
            name: customerGroup?.name,
            users: (customerGroup.users ?? [])?.length,
            id: customerGroup?.id,
          }))}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          filterMode='server'
          sortingMode='server'
          paginationMode='server'
          disableRowSelectionOnClick
          checkboxSelection={select}
          autoHeight
          autoPageSize
          onFilterModelChange={(filter) => {
            // setFilter(filter.items[0]);
            // setSearch(filter.quickFilterValues[0]);
          }}
          keepNonExistentRowsSelected
          onRowSelectionModelChange={(newRowSelectionModel) => {
            console.log(newRowSelectionModel);
            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".PrivateSwitchBase-input": {
              height: "23px",
              margin: "10px 13px",
              width: "20px",
            },
            cursor: "pointer",
          }}
          onRowClick={(params) => {
            if (nav) {
              navigate(`/customerGroups/${params.id}`);
            }
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          onSortModelChange={(sort) => {
            setSort(sort[0]);
          }}
        />
      </Box>

      {data?.maxPage > 1 && (
        <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
          <NewPagination totalPage={data} fetchData={getAllCustomerGroups} />
        </div>
      )}
    </div>
  );
};

export default CustomerGroups;
