import { TextField, IconButton } from "@mui/material";
import { Send, Close } from "@mui/icons-material";

const SentMedia = (props) => {
  const { media, text, setText, closeMedia, sendMedia } = props;

  let isImage = false;
  let image;

  if (media.type.startsWith("image")) {
    isImage = true;
    image = URL.createObjectURL(media);
  }

  return (
    <div
      className='rounded-lg p-4 shadow flex flex-col gap-1 items-stretch relative'
      style={{
        backgroundColor: "#000B17",
        width: "500px",
        maxWidth: "90%",
        height: "400px",
      }}
    >
      <div className='flex justify-end'>
        <Close
          className='  text-gray-200 cursor-pointer'
          onClick={() => {
            closeMedia?.();
          }}
        />
      </div>

      <div className='flex-grow flex flex-col items-center justify-center overflow-hidden'>
        {isImage ? <img src={image} className='max-h-60' alt='' /> : null}

        {media.size > 5242880 ? (
          <p className='text-red-600'>{`File size must be atmost ${
            5242880 / (1024 * 1024)
          } MB.`}</p>
        ) : null}

        <p className='text-gray-300'>{media.name}</p>
      </div>

      <div className='flex items-center gap-2'>
        <div className='flex-grow'>
          <TextField
            id='text'
            name='text'
            fullWidth
            // multiline
            // maxRows={5}
            required
            placeholder='Type a message'
            style={{
              borderRadius: "8px",
            }}
            inputProps={{
              style: {},
            }}
            InputProps={{
              style: {
                fontSize: "14px",
                fontWeight: "300",
                // padding: "0rem 0.05rem",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  "&:hover fieldset": {},
                  "&.Mui-focused fieldset": {},
                },
              },
            }}
            value={text}
            onChange={(e) => {
              setText(e.currentTarget.value);
            }}
          />
        </div>

        <IconButton
          type='button'
          style={{
            opacity: "0.6",
          }}
          disabled={media.size > 5242880}
          onClick={() => {
            sendMedia?.();
          }}
        >
          <Send />
        </IconButton>
      </div>
    </div>
  );
};

export default SentMedia;
