import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { Menu, ChevronLeft } from "@mui/icons-material";

import navLists from "./navLists";
import NL from "../components/nl/nl";
import { set as setScreen } from "../store/screenSlice";

const Nav = () => {
  const screen = useSelector((state) => state.screen);
  const screenWidth = screen.screenWidth;

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      component='nav'
      className={`border-r border-gray-300 transition-all [&>*]:p-2 ${
        screenWidth <= 700
          ? `fixed top-0 bottom-0 ${
              screen.openSidebar ? "!translate-x-0" : "-translate-x-full"
            }`
          : ""
      }`}
      style={{
        width: expanded ? "12rem" : "4rem",
        backgroundColor: "#12344d",
        color: "#bbdcfe",
        zIndex: "1000",
      }}
    >
      <Box
        component='div'
        className='overflow-hidden flex items-center'
        style={{
          height: `${screen.headerHeight}px`,
          justifyContent: expanded ? "space-between" : "center",
        }}
      >
        <Menu
          className='cursor-pointer'
          onClick={() => {
            if (screenWidth <= 700) {
              dispatch(setScreen({ openSidebar: !screen.openSidebar }));
            } else {
              toggleExpanded();
            }
          }}
        />

        {expanded ? (
          <ChevronLeft
            className='cursor-pointer'
            onClick={() => toggleExpanded()}
          />
        ) : null}
      </Box>

      <Box
        component='div'
        className='overflow-auto flex flex-col gap-2'
        style={{
          height: `calc(100vh - ${screen.headerHeight}px)`,
          alignItems: expanded ? "stretch" : "center",
        }}
      >
        {navLists.map((nl) => (
          <NL
            key={`Nav-${nl.text}`}
            expanded={expanded}
            nl={nl}
            hideNav={() => {
              dispatch(setScreen({ openSidebar: !screen.openSidebar }));
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Nav;
