import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box } from "@mui/material";
import { Menu } from "@mui/icons-material";

import { set as setScreen } from "../store/screenSlice";
import LogoutButton from "../components/logoutButton";
import Logo from "../assets/logo.svg";
import Notifications from "../components/notifications";

const Header = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const screen = useSelector((state) => state.screen);
  const screenWidth = screen.screenWidth;

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setScreen({ headerHeight: ref?.current?.offsetHeight ?? 0 }));
    });

    return () => {
      window.removeEventListener("resize", null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component='header'
      ref={ref}
      className='border-b border-gray-300 px-4 py-2 flex items-center justify-between'
    >
      <Box className='div flex item-center gap-4'>
        {screenWidth <= 700 ? (
          <Menu
            className='cursor-pointer'
            onClick={() => {
              dispatch(setScreen({ openSidebar: !screen.openSidebar }));
            }}
          />
        ) : null}
        <img src={Logo} alt='Depo24' className='h-6' />
      </Box>

      <Box component='div' className='flex items-center gap-4'>
        <LogoutButton />
        <Avatar
          sx={{ bgcolor: "primary.light", height: 32, width: 32 }}
          className='cursor-pointer'
        >
          {(user.display_name ?? user.name)[0]}
        </Avatar>

        <Notifications />
      </Box>
    </Box>
  );
};

export default Header;
