import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { TextField, Autocomplete, Button, Divider } from "@mui/material";
import { Send, Close } from "@mui/icons-material";

import axiosInstance from "../utilities/axiosInstance";
import { set as setSnackbar } from "../store/snackbarSlice";

const SendTemplate = (props) => {
  const { onClose, chat, addMessage } = props;
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const [temp, setTemp] = useState("");
  const [template, setTemplate] = useState(null);
  const [headerMedia, setHeaderMedia] = useState(null);
  const [headerPlaceholders, setHeaderPlaceholders] = useState("");
  const [bodyPlaceholders, setBodyPlaceholders] = useState("");

  useEffect(() => {
    if (temp) {
      const t = templates.find((e) => e.name === temp);

      setTemplate(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp]);

  const getTemplates = async () => {
    axiosInstance
      .get("/chats/templates")
      .then((res) => {
        setTemplates(res.data?.data ?? []);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const [loading, setLoading] = useState(false);
  const sendTemplate = async () => {
    const formData = new FormData();

    formData.append("to", chat.phone);
    if (template.header_type === "TEXT") {
      formData.append("headerPlaceholders", headerPlaceholders);
    } else if (
      template.header_type === "DOCUMENT" ||
      template.header_type === "IMAGE"
    ) {
      formData.append("media", headerMedia);
    }

    formData.append("bodyPlaceholders", bodyPlaceholders);

    setLoading(true);

    axiosInstance
      .post(`/chats/templates/${template.id}`, formData)
      .then((res) => {
        addMessage({ ...res.data.data, old: true });
        onClose();
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const getNumberOfUsers = async () => {
  //   axiosInstance
  //     .get("/users/count")
  //     .then((res) => {
  //       setShowNumberOfUsers(res.data.data);
  //     })
  //     .catch((err) => {});
  // };

  return (
    <div
      className='rounded-lg p-4 shadow flex flex-col items-stretch relative gap-4 text-gray-300'
      style={{
        backgroundColor: "#000B17",
        width: "500px",
        maxWidth: "90%",
        minHeight: "400px",
      }}
    >
      <Close
        className='absolute right-4 top-4 text-gray-200 cursor-pointer'
        onClick={() => {
          onClose?.();
        }}
      />

      {/* <Modal
        open={showWarning}
        onClose={() => setShowWarning(false)}
        className='flex items-center justify-center z-50'
        children={
          <div
            className='bg-white p-4 pt-12 rounded-lg flex flex-col gap-4 items-center'
            style={{
              width: "500px",
              maxWidth: "90%",
              minHeight: "400px",
            }}
          >
            <p className='text-center text-lg'>
              Are you sure to you want to send this template to all{" "}
              <span className='text-green-600 italic font-medium'>
                {numberOfUsers ?? ""}
              </span>{" "}
              users ?
            </p>

            <div className='flex items-center gap-4'>
              <div
                className='rounded-full w-32 p-2 bg-red-600 text-white flex items-center justify-center cursor-pointer'
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <p>No</p>
              </div>

              <div
                className='rounded-full w-32 p-2 bg-green-600 text-white flex items-center justify-center cursor-pointer'
                onClick={() => {
                  sendTemplate(true);
                }}
              >
                <p>Yes</p>
              </div>
            </div>
          </div>
        }
      ></Modal> */}

      <p className='text-center font-medium'>Send Template</p>

      <div className='flex-grow flex flex-col gap-3'>
        <Autocomplete
          id='template'
          value={temp}
          onChange={(e, v) => {
            setTemp(v);
          }}
          options={templates.map((e) => {
            return e.name;
          })}
          renderInput={(params) => (
            <TextField {...params} label='Select Template' />
          )}
        />

        {template ? (
          <Fragment>
            <h3>
              Header: <span>{template?.header_type}</span>
            </h3>

            {template?.header_type === "TEXT" ? (
              <Fragment>
                <p>{template.header_text}</p>
                <TextField
                  type='text'
                  label='Header Placeholder'
                  onChange={(e) => {
                    setHeaderPlaceholders(e.currentTarget.value);
                  }}
                />
              </Fragment>
            ) : null}

            {template?.header_type === "DOCUMENT" ||
            template?.header_type === "IMAGE" ? (
              <Fragment>
                <input
                  type='file'
                  accept={
                    template?.header_type === "DOCUMENT"
                      ? ".pdf, .csv, .xlxs"
                      : template?.header_type === "IMAGE"
                      ? ".png, .jpeg, .jpg, .webp"
                      : ""
                  }
                  label={`Select Media`}
                  onChange={(e) => {
                    setHeaderMedia(e.currentTarget.files[0]);
                  }}
                />

                {headerMedia && headerMedia?.size > 5242880 ? (
                  <p className='text-red-600'>{`File size must be atmost ${
                    5242880 / (1024 * 1024)
                  } MB.`}</p>
                ) : null}
              </Fragment>
            ) : null}

            <Divider></Divider>

            <h3>Body:</h3>

            <p>{template.body_text}</p>

            <TextField
              type='text'
              label='Body Placeholder'
              onChange={(e) => {
                setBodyPlaceholders(e.currentTarget.value);
              }}
            />

            <Divider></Divider>

            {template.footer_text ? (
              <Fragment>
                <h3>Footer:</h3>

                <p>{template.footer_text}</p>
              </Fragment>
            ) : null}

            <div className='flex justify-center items-center gap-4'>
              <Button
                type='button'
                variant='contained'
                endIcon={<Send />}
                disabled={
                  loading || (headerMedia && headerMedia?.size > 5242880)
                }
                onClick={() => sendTemplate()}
              >
                Send
              </Button>

              {/* <Button
                type='button'
                variant='contained'
                endIcon={<Send />}
                disabled={loading}
                onClick={() => {
                  getNumberOfUsers();
                  setShowWarning(true);
                }}
              >
                Send To All
              </Button> */}
            </div>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default SendTemplate;
