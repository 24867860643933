import { useDispatch } from "react-redux";
import { Launch } from "@mui/icons-material";

import { set as setSnackbar } from "../store/snackbarSlice";
import axiosInstance from "../utilities/axiosInstance";
import { handleCreatedAt } from "../utilities/utilityFunctions";

const Doc = (props) => {
  const { message } = props;
  const dispatch = useDispatch();

  const getMedia = () => {
    axiosInstance
      .get(`/chats/media/${message.chat_id}/${message.id}`)
      .then((res) => {
        window.open(res.data.data, "_blank");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err.response?.data?.message,
            open: true,
          })
        );
      });
  };

  return (
    <div
      className='p-4 pb-1 gap-1 cursor-pointer flex flex-col relative ml-3'
      style={{
        color: "#e9edff",
        backgroundColor: message.type === "sent" ? "#005c4b" : "#202c33",
        borderRadius: "0 4px 4px 4px",
      }}
      onClick={() => {
        getMedia();
      }}
    >
      <div
        className={`w-3 h-2 absolute top-0 right-full`}
        style={{
          backgroundColor: message.type === "sent" ? "#005c4b" : "#202c33",
          clipPath: "polygon(0 0, 100% 0, 100% 100%)",
        }}
      ></div>

      <div className='flex items-center justify-between'>
        <p>{message.filename ?? "Media"}</p>

        <Launch className='cursor-pointer' />
      </div>

      <div
        className='flex items-center justify-end gap-1'
        style={{ color: "#ffffff99" }}
      >
        {message.createdAt ? (
          <p style={{ fontSize: "10px" }}>
            {handleCreatedAt(new Date(message.createdAt))}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Doc;
