import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import RequestedTemplates from "../../../containers/requestedTemplates/requestedTemplates";

const RequestTemplates = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='flex items-center justify-between'>
        <h3 className='text-lg font-medium'>Requested Template</h3>

        <Button
          size='small'
          variant='contained'
          onClick={() => {
            navigate("/requestTemplate");
          }}
        >
          Request Template
        </Button>
      </div>

      <RequestedTemplates />
    </div>
  );
};

export default RequestTemplates;
