import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axiosInstance from "../../../utilities/axiosInstance";
import ChatList from "../../../containers/chatList";
import Chat from "../../../containers/chat";

import { socket } from "../../../socket";

import { setChatsData } from "../../../store/chatsSlice";

const Chats = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { isSocketConnected } = props;

  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notifications);
  const chatsData = useSelector((state) => state.chats.chatsData);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const chats = chatsData.chats;
  const chatData = {
    page: chatsData.page,
    maxPage: chatsData.maxPage,
  };
  const [chat, setChat] = useState(null);
  const [activeMessage, setActiveMessage] = useState(null);
  const [activeChatMessage, setActiveChatMessage] = useState(null);
  const [activeConversation, setActiveConversation] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const user = useSelector((state) => state.auth.user);
  const screen = useSelector((state) => state.screen);
  const screenWidth = screen.screenWidth;
  const [showChat, setShowChat] = useState(false);

  const chatsRef = useRef(null);
  const chatRef = useRef(null);
  const activeMessageRef = useRef(null);
  const activeChatMessageRef = useRef(null);
  chatsRef.current = chats;
  chatRef.current = chat;
  activeMessageRef.current = activeMessage;
  activeChatMessageRef.current = activeChatMessage;

  const [filter, setFilter] = useState(
    user.isAdminOrSuperAdmin
      ? "all-open"
      : user.isSupportManager
      ? "all-open-groups"
      : user.isSupportAgent
      ? "all-open-conversations"
      : ""
  );
  const [unreadMessages, setUnreadMessages] = useState(false);

  // const updateChat = (umc, index) => {
  //   const chs = [...chatsRef.current];
  //   let ch = { ...chs[index] };
  //   ch.unread_messages_count = +umc;
  //   chs[index] = ch;

  //   dispatch(setChatsData({ chats: chs }));
  // };

  const selectChat = (ch) => {
    setChat(ch);
    setShowChat(true);
  };

  const getChats = async (page = 1) => {
    setLoading(true);

    axiosInstance
      .get(
        `/chats/chats?page=${page}&filter=${filter}&unreadMessages=${unreadMessages}`
      )
      .then((res) => {
        const data = res.data.data;

        if (data.page === 1 || chatData.page < data.page) {
          dispatch(
            setChatsData({
              chats: [
                ...(data.page === 1 ? [] : chatsRef.current),
                ...data.chats,
              ],
              page: data.page,
              maxPage: data.maxPage,
            })
          );
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        setLoading2(false);
      });
  };

  useEffect(() => {
    setLoading2(true);
    getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, unreadMessages, notifications.updateChatsData]);

  useEffect(() => {
    socket.on("conversation", (payload) => {
      if (chat?.id === payload.chat.id) {
        setChat(payload.chat);
        setActiveConversation(payload.conversation);
      }
    });

    return () => {
      socket.off("conversation");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("message", (payload) => {
      // console.log(payload, "payload");
      // if (payload.chat.id === chat?.id) {
      //   setChat(payload.chat);
      //   setActiveConversation(payload.conversation);
      // }

      if (payload.message.id !== activeMessageRef.current?.id) {
        setActiveMessage(payload.message);

        if (chatRef.current?.id === payload.chat.id) {
          setActiveChatMessage(payload.message);
          setActiveConversation(payload.conversation);
          setChat(payload.chat);

          const index = chatsRef.current.findIndex(
            (e) => e.id === payload.chat.id
          );
          console.log(index);
          if (index > -1) {
            const chs = [...chatsRef.current];
            let ch = { ...chs[index] };
            let unread_messages_count = +ch.unread_messages_count + 1;
            ch.unread_messages_count = unread_messages_count;
            ch.message = payload.message;
            chs.splice(index, 1);
            chs.unshift(ch);

            dispatch(
              setChatsData({
                chats: chs,
              })
            );
          } else {
            const chs = [...chatsRef.current];
            let ch = payload.chat;
            let unread_messages_count = +ch.unread_messages_count + 1;
            ch.unread_messages_count = unread_messages_count;
            ch.message = payload.message;
            chs.unshift(ch);

            dispatch(
              setChatsData({
                chats: chs,
              })
            );
          }
        } else if (payload.message.type === "received") {
          const index = chatsRef.current.findIndex(
            (e) => e.id === payload.chat.id
          );
          console.log(index);
          if (index > -1) {
            const chs = [...chatsRef.current];
            let ch = { ...chs[index] };
            let unread_messages_count = +ch.unread_messages_count + 1;
            ch.unread_messages_count = unread_messages_count;
            ch.message = payload.message;
            chs.splice(index, 1);
            chs.unshift(ch);

            dispatch(
              setChatsData({
                chats: chs,
              })
            );
          } else {
            const chs = [...chatsRef.current];
            let ch = payload.chat;
            let unread_messages_count = +ch.unread_messages_count + 1;
            ch.unread_messages_count = unread_messages_count;
            ch.message = payload.message;
            chs.unshift(ch);

            dispatch(
              setChatsData({
                chats: chs,
              })
            );
          }
        }
      }
    });

    return () => {
      socket.off("message");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     socket.emit("setup", user.id);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  const setLastMessage = (chat, message) => {
    const index = chatsRef.current.findIndex((e) => e.id === chat.id);
    console.log(index);
    if (index > -1) {
      const chs = [...chatsRef.current];
      let ch = { ...chs[index] };
      ch.message = message;
      chs.splice(index, 1);
      chs.unshift(ch);

      dispatch(
        setChatsData({
          chats: chs,
        })
      );
    } else {
      const chs = [...chatsRef.current];
      let ch = { ...chat };
      ch.message = message;
      chs.unshift(ch);

      dispatch(
        setChatsData({
          chats: chs,
        })
      );
    }
  };

  useEffect(() => {
    if (notifications.chat) {
      setChat(notifications.chat);

      // dispatch(setNotification({ chat: null, openModal: false }));
    }
  }, [notifications.chat, dispatch]);

  const getActiveChatFromLocalStorage = () => {
    const activeChatPhone = window.localStorage.getItem("activeChat");

    axiosInstance
      .get(`/chats/chatByPhoneNumber/${activeChatPhone}`)
      .then((res) => {
        selectChat(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    getActiveChatFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.updateChatsData]);

  const muiTheme = createTheme({
    palette: {
      mode: "dark",
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
          },
        },
        defaultProps: {
          InputProps: {
            style: {
              fontSize: "12px",
              fontWeight: "300",
            },
          },
          InputLabelProps: {
            style: {
              fontSize: "14px",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(255, 255, 255, 0.04)",
            fontSize: "14px",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: "12px",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          colorPrimary: {
            color: "#5C5C5C",
          },
          thumb: {
            color: "#ffffff",
            height: "16px",
            width: "12px",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#ffffff",
            backgroundColor: "rgba(255, 255, 255, 0.04)",
            borderColor: "rgba(255, 255, 255, 0.14)",
            fontSize: "12px",
            fontWeight: "300",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <div
        className='flex h-full max-h-full overflow-auto [&>*]:h-full [&>*]:max-h-full [&>*]:overflow-auto rounded relative'
        style={{
          backgroundColor: "#111b21",
        }}
      >
        <ChatList
          chats={chats}
          activeChat={chat}
          selectChat={selectChat}
          // updateChat={updateChat}
          filter={filter}
          setFilter={setFilter}
          getChats={getChats}
          loading={loading}
          chatData={chatData}
          unreadMessages={unreadMessages}
          setUnreadMessages={setUnreadMessages}
          loading2={loading2}
        />
        {(screenWidth <= 700 && showChat) || screenWidth > 700 ? (
          <div
            className={`flex-grow ${
              screenWidth <= 700
                ? "absolute top-0 bottom-0 left-0 right-0 z-40"
                : ""
            }`}
            style={{
              backgroundColor: "#111b21",
              width: `${screenWidth <= 700 ? "100%" : "75%"}`,
              maxWidth: `${screenWidth <= 700 ? "100%" : "75%"}`,
            }}
          >
            {chat ? (
              <Chat
                key={chat.phone}
                chat={chat}
                activeChatMessage={activeChatMessage}
                activeConversation={activeConversation}
                setActiveMessage={setActiveMessage}
                setActiveChatMessage={setActiveChatMessage}
                setActiveConversation={setActiveConversation}
                closeChat={() => {
                  setShowChat(false);
                }}
                setLastMessage={setLastMessage}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </ThemeProvider>
  );
};

export default Chats;
