import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { set as setSnackbar } from "../store/snackbarSlice";
import axiosInstance from "../utilities/axiosInstance";
import { handleCreatedAt } from "../utilities/utilityFunctions";

const Media = (props) => {
  const { message } = props;
  const dispatch = useDispatch();

  const [image, setImage] = useState(null);

  const getMedia = () => {
    axiosInstance
      .get(`/chats/media/${message.chat_id}/${message.id}`)
      .then((res) => {
        window.open(res.data.data, "_blank");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err.response?.data?.message,
            open: true,
          })
        );
      });
  };

  const getImage = async () => {
    const ma = message.media.split(".");
    let extension = ma[ma.length - 1];

    if (
      extension === "png" ||
      extension === "jpeg" ||
      extension === "jpg" ||
      extension === "webp"
    ) {
      axiosInstance
        .get(`/chats/media/${message.chat_id}/${message.id}`)
        .then((res) => {
          setImage(res.data.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (message.media) {
      getImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='rounded-sm p-4 pb-1 gap-1 !aspect-square cursor-pointer flex flex-col'
      style={{
        backgroundColor: "#2a3942",
      }}
      onClick={() => {
        getMedia();
      }}
    >
      <div className='flex-grow !aspect-square overflow-hidden'>
        <img src={image} className='w-full' alt='' />
      </div>

      <div
        className='flex items-center justify-end gap-1'
        style={{ color: "#ffffff99" }}
      >
        {message.createdAt ? (
          <p style={{ fontSize: "10px" }}>
            {handleCreatedAt(new Date(message.createdAt))}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Media;
