import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatsData: {
    chats: [],
    page: 1,
    maxPage: 1,
  },
  chatsSearchData: {
    users: [],
    chats: [],
  },
};

const chatsSlice = createSlice({
  name: "chats",
  initialState: initialState,
  reducers: {
    setChatsData: (state, action) => ({
      ...state,
      chatsData: {
        ...state.chatsData,
        ...action.payload,
      },
    }),
    setChatsSearchData: (state, action) => ({
      ...state,
      chatsSearchData: {
        ...state.chatsSearchData,
        ...action.payload,
      },
    }),
    reset: (state, action) => ({
      ...initialState,
    }),
  },
});

export const { setChatsData, setChatsSearchData } = chatsSlice.actions;

export default chatsSlice.reducer;
