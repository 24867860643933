import { TextField, Autocomplete, } from "@mui/material";

const orderStatuses = ['Confirmed',
    'Part Allocated',
    'Part Packed',
    'Partially Dispatched',
    'Partially Delivered',
    'Part Delivered & Cancelled',
    'Partially Fulfilled',
    'Partially Return Requested',
    'Partially Returned',
    'Allocated',
    'Packed',
    'Dispatched',
    'Delivered',
    'Cancelled',
    'Return Requested',
    'Returned',
]

const StatusInput = ({ status, handleStatusFilter }) => {
    return (
        <div style={{ width: '90%' }}>
            <Autocomplete
                multiple
                limitTags={1}
                size="small"
                disablePortal
                sx={{ width: '100%' }}
                options={orderStatuses}
                value={status}
                onChange={handleStatusFilter}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                    />
                )}
            />
        </div>
    )
}

export default StatusInput;