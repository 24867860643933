import { configureStore } from "@reduxjs/toolkit";

import screenReducer from "./screenSlice";
import authReducer from "./authSlice";
import snackbarSlice from "./snackbarSlice";
import notificationsSlice from "./notificationsSlice";
import chatsSlice from "./chatsSlice";

export default configureStore({
  reducer: {
    screen: screenReducer,
    auth: authReducer,
    snackbar: snackbarSlice,
    notifications: notificationsSlice,
    chats: chatsSlice,
  },
});
