import { useState, useEffect, useRef } from "react";
import { CircularProgress, Box, Paper, TextField } from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

import CustomToolbar from "../../../table/CustomToolbar";
import axiosInstance from "../../../utilities/axiosInstance";
import NewPagination from "../../../components/newPagination";

const AllCustomers = () => {
  const [data, setData] = useState({
    chats: [],
    page: 1,
    maxPage: 1,
  });

  const [text, setText] = useState("");

  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });

  const [loading, setLoading] = useState(false);
  const apiRef = useRef(null);

  const columns = [
    {
      flex: 0.5,
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
    },
    // {
    //   field: "customer_type",
    //   headerName: "TYPE",
    //   headerClassName: "super-app-theme--header",
    //   headerAlign: "center",
    //   align: "center",
    //   filterable: false,
    //   sortable: true,
    //   flex: 1,
    //   renderCell: (params) =>
    //     params.value === "B2C" ? (
    //       <Chip icon={<PersonIcon />} label={params.value} />
    //     ) : (
    //       <Chip icon={<StoreIcon />} label={params.value} />
    //     ),
    // },

    // {
    //   field: "customer_role",
    //   headerName: "Customer Role",
    //   filterable: false,
    //   flex: 1,
    //   headerClassName: "super-app-theme--header",
    //   sortable: true,
    // },

    {
      field: "customer_code",
      headerName: "Customer Code",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },

    {
      field: "pre_sales_user_admin_name",
      headerName: "Pre Sales User",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },

    {
      field: "field_sales_user_admin_name",
      headerName: "Field Sales User",
      filterable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  const getAllChats = async (page = 1) => {
    setLoading(true);

    if (apiRef.current) {
      apiRef.current.abort();
    }

    apiRef.current = new AbortController();

    const filters = [];

    axiosInstance
      .get(
        `/customers/chats?page=${page}&sort=${JSON.stringify(
          sort
        )}&text=${text}&filters=${JSON.stringify(filters)}`,
        {
          signal: apiRef.current.signal,
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, text]);

  return (
    <div
      className='relative'
      style={{
        maxWidth: "calc(100vw - 1.25rem)",
      }}
    >
      <Box
        component='div'
        className='flex items-center justify-between rounded bg-gray-100 p-2 mb-4'
      >
        <p className='font-medium text-lg'>All Chats</p>
      </Box>

      {loading ? (
        <div className='absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-white opacity-50'>
          <CircularProgress />
        </div>
      ) : null}

      <div className='my-2 grid grid-flow-row grid-cols-3 gap-4'>
        <TextField
          size='small'
          fullWidth
          id='text-search'
          label='Search'
          placeholder='Search for name, email, phone'
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          style={{
            gridColumn: "1 / -1",
          }}
        />
      </div>

      <Box
        className='shadow overflow-auto'
        component={Paper}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#243750",
            color: "#ffffff",
            cursor: "default",
          },
        }}
      >
        <DataGrid
          rows={data?.chats?.map((chat) => ({
            name: chat?.name,
            phone: chat?.phone,
            customer_code: chat?.customer_code,
            pre_sales_user_admin_name: chat?.pre_sales_user_admin_name,
            field_sales_user_admin_name: chat?.field_sales_user_admin_name,
            id: chat?.id,
          }))}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          filterMode='server'
          sortingMode='server'
          paginationMode='server'
          disableRowSelectionOnClick
          // checkboxSelection={true}
          autoHeight
          autoPageSize
          onFilterModelChange={(filter) => {
            // setFilter(filter.items[0]);
            // setSearch(filter.quickFilterValues[0]);
          }}
          // keepNonExistentRowsSelected
          // onRowSelectionModelChange={(newRowSelectionModel) => {
          //   console.log(newRowSelectionModel);
          //   setSelectedRows(newRowSelectionModel);
          // }}
          // rowSelectionModel={selectedRows}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".PrivateSwitchBase-input": {
              height: "23px",
              margin: "10px 13px",
              width: "20px",
            },
            cursor: "pointer",
            minWidth: "800px",
          }}
          onRowClick={(params) => {}}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          onSortModelChange={(sort) => {
            setSort(sort[0]);
          }}
        />
      </Box>

      {data?.maxPage > 1 && (
        <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
          <NewPagination totalPage={data} fetchData={getAllChats} />
        </div>
      )}
    </div>
  );
};

export default AllCustomers;
