import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  // FormControlLabel,
  // Checkbox,
} from "@mui/material";

const ChatFilter = (props) => {
  const user = useSelector((state) => state.auth.user);

  const { filter, setFilter, unreadMessages, setUnreadMessages } = props;

  let filters = [
    { value: "", label: "None" },
    { value: "all-open", label: "All Open Conversation" },
    { value: "all-resolved", label: "All Resolved Conversation" },
    { value: "all-closed", label: "All Closed Conversation" },
  ];

  if (!user.isAdminOrSuperAdmin) {
    const f = [
      {
        value: "all-open-groups",
        label: "All Open Conversation Assigned To My Group",
      },
      {
        value: "all-resolved-groups",
        label: "All Resolved Conversation Assigned To My Group",
      },
      {
        value: "all-closed-groups",
        label: "All Closed Conversation Assigned To My Group",
      },
    ];

    filters = [...filters, ...f];

    if (!user.isSupportManager) {
      const ff = [
        {
          value: "all-open-conversations",
          label: "All Open Conversation Assigned To Me",
        },
        {
          value: "all-resolved-conversations",
          label: "All Resolved Conversation Assigned To Me",
        },
        {
          value: "all-closed-conversations",
          label: "All Closed Conversation Assigned To Me",
        },
      ];

      filters = [...filters, ...ff];
    }
  }

  return (
    <div className='px-2 pt-2'>
      <FormControl fullWidth>
        <InputLabel id='filter-chats-label'>Filter Chats</InputLabel>
        <Select
          size='small'
          labelId='filter-chats-label'
          id='filter-chats'
          value={filter}
          label='Filter Chats'
          placeholder='Filter Chats'
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          input={<OutlinedInput notched label={"Filter Chats"} />}
          style={{
            borderRadius: "4px",
          }}
          inputProps={{
            style: {
              padding: "0.5rem 1rem",
            },
          }}
          InputProps={{
            style: {
              fontSize: "14px",
              fontWeight: "400",
              backgroundColor: "#202c33",
              color: "#d1d7db",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
                "&:hover fieldset": {},
                "&.Mui-focused fieldset": {},
              },
            },
          }}
        >
          {filters.map((e) => {
            return (
              <MenuItem value={e.value} key={"filter-chats" + e.label}>
                {e.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      {/* <FormControlLabel
        style={{
          color: "#d1d7db",
        }}
        control={
          <Checkbox
            checked={unreadMessages}
            onChange={(e) => {
              setUnreadMessages(!unreadMessages);
            }}
          />
        }
        label='Unread Messages'
      /> */}
      <div className='mt-2 flex items-center gap-2 [&>p]:rounded-full [&>p]:px-4 [&>p]:py-1 [&>p]:cursor-pointer [&>p]:text-xs'>
        <p
          style={{
            backgroundColor: !unreadMessages ? "#00a88433" : "#202c33",
            color: !unreadMessages ? "#00a884" : "#d1d7db",
          }}
          onClick={() => {
            setUnreadMessages(false);
          }}
        >
          All
        </p>
        <p
          style={{
            backgroundColor: unreadMessages ? "#00a88433" : "#202c33",
            color: unreadMessages ? "#00a884" : "#d1d7db",
          }}
          onClick={() => {
            setUnreadMessages(true);
          }}
        >
          Unread
        </p>
      </div>
    </div>
  );
};

export default ChatFilter;
