import { TextField, Button, CircularProgress } from "@mui/material";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";

import { set as setSnackbar } from "../store/snackbarSlice";
import axiosInstance from "../utilities/axiosInstance";

const CreateUpdateGroupViaCSV = (props) => {
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState("");
  const [csv, setCSV] = useState(null);
  const csvRef = useRef();

  const [loading, setLoading] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("groupName", groupName);
    formData.append("csv", csv);

    setLoading(true);

    axiosInstance
      .post("/customers/createUpdateCustomerViaCSV", formData)
      .then((res) => {
        props.setFetch(Date.now());
        props.close();
        setGroupName("");
        setCSV(null);
        csvRef.current.value = "";
        dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: res?.data?.message,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadSample = () => {
    const data =
      "Name,Phone,Type\nAjay,7987436563,add\nAjay2,9079531873,remove";

    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute(
      "download",
      `${`customer group template - ${new Date()}`}.csv`
    );
    a.click();
  };

  return (
    <div
      className='bg-white rounded p-4 pt-2 text-black relative'
      style={{
        width: "90%",
        maxWidth: "500px",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <h4>Crete Update Customer Group Via CSV</h4>

      <Close
        className='absolute top-2 right-4 cursor-pointer'
        onClick={() => {
          props?.close();
        }}
      />

      <form
        className='flex flex-col items-center mt-4 gap-4'
        onSubmit={onSubmit}
      >
        <TextField
          style={{
            width: "90%",
          }}
          size='small'
          id='GroupName'
          label='Group Name'
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
          }}
          required
        />

        <div
          style={{
            width: "90%",
          }}
          className='border rounded p-2 pt-3 border-gray-400 relative'
          onClick={() => {
            csvRef?.current?.click();
          }}
        >
          <p className='absolute left-4 top-0 -translate-y-1/2 bg-white text-gray-500 px-1 text-xs font-light'>
            Customers CSV File
          </p>
          <input
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={csvRef}
            required
            disabled={loading}
            type='file'
            accept={".csv"}
            name='header_media'
            onChange={(e) => {
              setCSV(e.currentTarget.files[0]);
            }}
          />
        </div>

        <div
          style={{
            width: "90%",
          }}
        >
          <Button
            onClick={() => {
              downloadSample();
            }}
          >
            Download Sample
          </Button>
        </div>

        <Button
          disabled={loading}
          type='submit'
          variant='contained'
          size='small'
          style={{
            width: "120px",
          }}
        >
          {loading ? (
            <CircularProgress size={24} className='!text-white' />
          ) : (
            "Submit"
          )}
        </Button>
      </form>
    </div>
  );
};

export default CreateUpdateGroupViaCSV;
