import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box, Paper, TextField } from "@mui/material";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

import CustomToolbar from "../../table/CustomToolbar";
import axiosInstance from "../../utilities/axiosInstance";
import NewPagination from "../../components/newPagination";

import { handleCreatedAt } from "../../utilities/utilityFunctions";

const RequestedTemplates = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    requestedTemplates: [],
    page: 1,
    maxPage: 1,
  });

  const [text, setText] = useState("");

  const [sort, setSort] = useState({ field: "createdAt", sort: "desc" });

  const [loading, setLoading] = useState(false);
  const apiRef = useRef(null);
  const getAllRequestedTemplates = async (page = 1) => {
    setLoading(true);

    if (apiRef.current) {
      apiRef.current.abort();
    }

    apiRef.current = new AbortController();

    axiosInstance
      .get(
        `/chats/requestTemplates?page=${page}&sort=${JSON.stringify(
          sort
        )}&text=${text}`,
        {
          signal: apiRef.current.signal,
        }
      )
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllRequestedTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, text]);

  const columns = [
    {
      flex: 0.5,
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "requested_by",
      headerName: "REQUESTED BY",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "header_type",
      headerName: "HEADER TYPE",
      filterable: true,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      filterable: true,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "approved_by",
      headerName: "APPROVED BY",
      filterable: true,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "rejected_by",
      headerName: "REJECTED BY",
      filterable: true,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "completed_by",
      headerName: "COMPLETED BY",
      filterable: true,
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];

  if (document.querySelector(".MuiDataGrid-footerContainer")) {
    document.querySelector(".MuiDataGrid-footerContainer").style.display =
      "none";
  }

  return (
    <div className='relative mt-8'>
      {loading ? (
        <div className='absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 bg-white opacity-50'>
          <CircularProgress />
        </div>
      ) : null}

      <div className='mb-2'>
        <TextField
          size='small'
          fullWidth
          id='text-search'
          label='Search'
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
      </div>

      <Box
        className='shadow overflow-auto'
        component={Paper}
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: "#243750",
            color: "#ffffff",
            cursor: "default",
          },
        }}
        style={{
          maxWidth: "calc(100vw - 1rem)",
        }}
      >
        <DataGrid
          rows={data?.requestedTemplates?.map((rq) => ({
            name: rq?.name,
            status: rq?.status,
            requested_by: rq?.requested_by,
            header_type: rq?.header_type,
            createdAt: handleCreatedAt(new Date(rq?.createdAt)),
            approved_by: rq?.approved_by,
            rejected_by: rq?.rejected_by,
            completed_by: rq?.completed_by,
            id: rq?.id,
          }))}
          columns={columns}
          slots={{ toolbar: CustomToolbar }}
          filterMode='server'
          sortingMode='server'
          paginationMode='server'
          disableRowSelectionOnClick
          autoHeight
          autoPageSize
          onFilterModelChange={(filter) => {
            // setFilter(filter.items[0]);
            // setSearch(filter.quickFilterValues[0]);
          }}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".PrivateSwitchBase-input": {
              height: "23px",
              margin: "10px 13px",
              width: "20px",
            },
            cursor: "pointer",
            minWidth: "1000px",
          }}
          onRowClick={(params) => {
            navigate(`${params.id}`);
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          onSortModelChange={(sort) => {
            setSort(sort[0]);
          }}
        />
      </Box>

      {data?.maxPage > 1 && (
        <div style={{ margin: "20px auto 0px", paddingBottom: 20 }}>
          <NewPagination
            totalPage={data}
            fetchData={getAllRequestedTemplates}
          />
        </div>
      )}
    </div>
  );
};

export default RequestedTemplates;
