import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
  Chip,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";

import axiosInstance from "../../../utilities/axiosInstance";
import { set as setSnackbar } from "../../../store/snackbarSlice";

const AgentDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [agents, setAgents] = useState([]);

  const [assignedAgents, setAssignedAgents] = useState([]);
  const [enabled, setEnabled] = useState(false);

  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    const newData = [];

    agents.forEach((e) => {
      const conversations = [];

      data.conversations.forEach((c) => {
        if (c.lc_assigned_groups.includes(e.groupId)) {
          conversations.push(c.lc_id);
        }
      });

      newData.push({
        ...e,
        conversations,
        assigned_agents: [],
      });
    });

    setAssignedAgents(newData);
  }, [agents, data]);

  useEffect(() => {
    let enabled = false;

    for (let i = 0; i < assignedAgents.length; i++) {
      let en = false;

      const assignedAgent = assignedAgents[i];

      if (!assignedAgent.conversations.length) {
        en = true;

        if (assignedAgents.length === 1) {
          en = false;
        }
      } else if (
        assignedAgent.assigned_agents.length &&
        assignedAgent.start_date &&
        assignedAgent.end_date
      ) {
        en = true;
      }

      enabled = en;
    }

    setEnabled(enabled);
  }, [assignedAgents]);

  const getData = async () => {
    setLoading(true);
    setError(null);

    axiosInstance
      .get(`/chats2/allAgents/${id}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAgents = () => {
    axiosInstance
      .post("/chats2/allAgentsGroups", {
        groupIds: data?.group_ids ?? [],
        not: data?.id,
      })
      .then((res) => {
        setAgents(res.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (data) {
      getAllAgents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const submit = async () => {
    // console.log(assignedAgents);

    const data = {
      user: +id,
      data: [],
    };

    assignedAgents.forEach((e) => {
      if (e.conversations.length && e.assigned_agents.length) {
        const ags = [];

        e.agents.forEach((a) => {
          if (e.assigned_agents.includes(a.username)) {
            ags.push(a.id);
          }
        });

        data.data.push({
          group_id: +e.groupId,
          conversations: e.conversations,
          assigned_agents: ags,
          start_date: e.start_date,
          end_date: e.end_date,
        });
      }
    });

    axiosInstance
      .post("/chats2/assignTemporaryAccess", data)
      .then((res) => {
        navigate("/allAgents");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            open: true,
            message: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  return (
    <Fragment>
      <Box
        component='div'
        className='flex items-center justify-between rounded bg-gray-100 p-2 mb-4'
      >
        <p className='font-medium text-lg'>Agent Detail</p>
      </Box>

      {loading || error ? (
        <div className='mt-12'>
          {loading ? (
            <div className='flex justify-center items-center'>
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity='error'>
              <AlertTitle>Error</AlertTitle>
              {error} — <strong>check it out!</strong>
            </Alert>
          ) : null}
        </div>
      ) : null}

      {data ? (
        <div>
          <div className='flex items-center gap-8 text-sm font-medium'>
            <div className='flex items-center gap-2 bg-gray-100 p-2 rounded '>
              <p className='text-gray-700'>Agent Name:</p>
              <p>{data.name}</p>
            </div>

            <div className='flex items-center gap-1 bg-gray-100 p-2 rounded '>
              <p className='text-gray-700'>Agent Username:</p>
              <p>{data.username}</p>
            </div>
          </div>

          <div className='flex items-center gap-4 mt-4'>
            <p className='text-sm font-medium text-gray-700'>Groups:</p>
            <div className='flex items-center gap-2'>
              {data.groups?.map((e) => (
                <Chip key={e.id} label={e.name} />
              ))}
            </div>
          </div>

          <div className='flex items-center gap-4 mt-4'>
            <p className='text-sm font-medium text-gray-700'>
              Total Conversations Assigned:
            </p>
            <p>{data.conversations?.length ?? 0}</p>
          </div>

          <div className='mt-4 grid grid-flow-row grid-cols-2 gap-x-8 gap-y-8'>
            {assignedAgents.map((e, index) => {
              return (
                <div key={e.groupId + e.groupName}>
                  <p>
                    {e.groupName}{" "}
                    <span>({e.conversations.length} conversations)</span>
                  </p>
                  <Autocomplete
                    disabled={!e.conversations.length}
                    className='!mt-1'
                    id='supportAgents'
                    multiple
                    value={e.assigned_agents}
                    onChange={(e, v) => {
                      setAssignedAgents((prev) => {
                        const temp = [...prev];
                        temp[index].assigned_agents = v;

                        return temp;
                      });
                    }}
                    options={e.agents.map((a) => {
                      return a.username;
                    })}
                    renderInput={(params) => (
                      <TextField {...params} label='Support Agents' />
                    )}
                  />

                  <div className='flex items-center gap-8 mt-1'>
                    <div>
                      <p>Start Date</p>
                      <input
                        disabled={!e.conversations.length}
                        className='outline-none border border-gray-300 rounded p-2 text-gray-600'
                        type='date'
                        value={e.start_date ?? ""}
                        onChange={(e) => {
                          setAssignedAgents((prev) => {
                            const temp = [...prev];
                            temp[index].start_date = e.target.value;

                            return temp;
                          });
                        }}
                      />
                    </div>

                    <div>
                      <p>End Date</p>
                      <input
                        disabled={!e.conversations.length}
                        className='outline-none border border-gray-300 rounded p-2 text-gray-600'
                        type='date'
                        value={e.end_date ?? ""}
                        onChange={(e) => {
                          setAssignedAgents((prev) => {
                            const temp = [...prev];
                            temp[index].end_date = e.target.value;

                            return temp;
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <Button
            disabled={!enabled || loading2}
            variant='contained'
            type='button'
            size='small'
            className='!mt-12'
            onClick={() => {
              submit();
            }}
          >
            {"Assign Temporary Access"}
          </Button>
        </div>
      ) : null}
    </Fragment>
  );
};

export default AgentDetail;
