import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight } from "@mui/icons-material";
import { TextField, Button, CircularProgress } from "@mui/material";

import axiosInstance from "../../../utilities/axiosInstance";
import Customers from "../../../containers/customers/customers";
import { set as setSnackbar } from "../../../store/snackbarSlice";

const CreateCustomerGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = useSelector((state) => state.screen.screenWidth);

  const [name, setName] = useState("");
  const [selectUsers, setSelectUsers] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const submit = async () => {
    if (selectedUsers.length <= 0) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Please select at least one user!",
        })
      );

      return;
    }

    if (!name) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Please Enter name!",
        })
      );

      return;
    }

    const data = {
      name: name,
      users: selectedUsers,
    };

    setLoading(true);

    axiosInstance
      .post(`/customers/customerGroups`, data)
      .then((res) => {
        navigate("/customerGroups");
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            severity: "error",
            message: err?.response?.data?.message,
            open: true,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className={`${screenWidth <= 700 ? "p-1" : "p-8"} flex flex-col gap-8`}
      style={{
        maxWidth: "calc(100vw - 1rem)",
      }}
    >
      {/* Select Users */}
      <div className='shadow'>
        <div
          className=' flex items-center gap-4 cursor-pointer border-b p-2'
          onClick={() => {
            setSelectUsers(!selectUsers);
          }}
        >
          <ArrowRight
            size={32}
            className={`transition-all ${selectUsers ? "rotate-90" : ""}`}
          />

          <h3 className='text-lg font-medium'>Select Users</h3>

          <div className='flex-grow flex items-center justify-end'>
            {selectedUsers.length ? (
              <p>{selectedUsers.length} Users Selected</p>
            ) : null}
          </div>
        </div>

        <div
          className='overflow-auto flex flex-col gap-3 transition-all'
          style={{
            height: selectUsers ? "auto" : "0px",
            // padding: selectUsers ? "1rem" : "0px",
          }}
        >
          <Customers
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
      </div>

      <div className='flex items-center flex-wrap gap-8'>
        <TextField
          size='small'
          id='Name'
          label='Name'
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <Button
          variant='contained'
          disabled={loading}
          onClick={() => {
            submit();
          }}
        >
          {loading ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "Create Customer Group"
          )}
        </Button>
      </div>
    </div>
  );
};

export default CreateCustomerGroup;
